import axios from 'axios';


export const SaveQuestionary = (questionary) => {
    questionary.DateCreated = new Date();
    const _url = "/questionaries";
    return axios.post(_url, questionary);
}
export const GetQuestionary = (_id, _clientId) => {
    const _url = "/questionaries";
    const params = {
        Id: _id,
        ClientId: _clientId
    }
    return axios.get(_url, { params });
}
export const UpdateQuestionary = (questionary) => {
    const _url = "/questionaries";
    return axios.put(_url, questionary);
}
export const DeleteQuestionaryAPI = (_questionaryId) => {
    const _url = "/questionaries";
    const params = {
        questionaryId: _questionaryId
    }
    return axios.delete(_url, { params });
}

export const SendQuestionary = (_questionaryId, _contactId, _supplierId, _dueDate,_ClientId) => {
    const _url = "/questionaries/sendQuestionary";
    const params = {
        questionaryId: _questionaryId,
        contactId: _contactId,
        supplierId: _supplierId,
        dueDate: _dueDate != undefined ? _dueDate : new Date(),
        ClientId:_ClientId
    }
    return axios.post(_url, params);
}

export const ApproveQuestionary = (_questionaryId) => {
    const _url = "/questionaries/approve";
    const params = {
        questionaryId: _questionaryId
    }
    const customConfig = {
        headers: {
        'Content-Type': 'application/json'
        }
    };
    return axios.post(_url, _questionaryId,customConfig);
}

export const RejectQuestionary = (_questionaryId) => {
    const _url = "/questionaries/reject";
    const params = {
        questionaryId: _questionaryId
    }
    const customConfig = {
        headers: {
        'Content-Type': 'application/json'
        }
    };
    return axios.post(_url, _questionaryId,customConfig);
}
export const GetQuestionaries=(ClientId)=>{
    const _url="/questionaries/getsent";
    const params={
        ClientId:ClientId
    }
    return axios.get(_url,{params})
}