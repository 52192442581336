import axios from 'axios';
export const GetAssets = (_id, _supplierId) => {
    const _url = "/assets";
    const params = {
        Id: _id,
        SupplierId: _supplierId
    }
    return axios.get(_url, { params });
}
export const IgnoreScan = (_id) => {
    const _url = "/assetscans/ignore";
    const params = {
        assetScanId: _id
    };
    return axios.get(_url,  {params} );
}
export const AddAsset=(_asset)=>{
    const _url="/assets";
    return axios.post(_url,_asset)
}
export const UpdateAsset=(_asset)=>{
    const _url="/assets";
    
    return axios.put(_url,_asset);
}