import React,{useContext} from 'react';
import "./LoaderContainer.css";
import { ColorRing } from 'react-loader-spinner';
import { ThemeContext } from '../../../contexts/themeContext';
const LoaderContainer = (props) => {
     const {theme } = useContext(ThemeContext);
    
    return (
        <div className="LoaderContainer">
            <ColorRing
                visible={true}
                height="80"
                width="80"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={theme=="dark"?['#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF']:['#1861ac', '#1861ac', '#1861ac', '#1861ac', '#1861ac']}
            />
        </div>
    )

}
export default LoaderContainer;