const RatingFormulaCalculator = (formula, values) => {

  //console.log(values);
  const PostureRating = values.PostureRating;
  const QuestionaryRating = values.QuestionaryRating;
  const BusinessImpact = values.BusinessImpact;
  const AssetBusinessImpact = values.AssetBusinessImpact;
  const r = formula.replace("PostureRating", PostureRating).replace("QuestionaryRating", QuestionaryRating).replace("BusinessImpact", BusinessImpact).replace("AssetBusinessImpact", AssetBusinessImpact);
  
  let result = eval(r);
  // console.log(result);
  const score = result;
  result = Math.floor(4 - (result * 3 / 100));
  return { rating: result, score: score };
}
export default RatingFormulaCalculator;