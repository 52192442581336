import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { ReactFormGenerator, ElementStore, Registry } from 'react-form-builder2';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import DcsToggle from './Toggle/DcsToggle';
import { SaveQuestionary, UpdateQuestionary } from '../../../data/questionary';
import { Form, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../../contexts/authContext';
const FormBuilderPreview = forwardRef((props, ref) => {
  const { id } = useParams();
  const { getQuestionaryName } = props;
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [answers, setAnswers] = useState();
  const NameRef = useRef(null);
  const QWeight = useRef(null);

  const [previewVisible, setPreviewVisible] = useState(false);
  const auth = useAuth();
  ElementStore.subscribe(state => {
    setData(state.data);
    if (state.data != undefined && state.data.length > 0) {
      console.log("totalWeight:" + state.data.reduce((a, b) => a + (b.weigth != undefined ? Number(b.weigth) : 0), 0));
    }
    console.log(data);
  })

  const ShowPreivew = () => {
    setPreviewVisible(true);
  }
  const ClosePreview = () => {
    setPreviewVisible(false);
  }


  const saveFormData = () => {
    const Questionary = {
      Name: NameRef.current.value,
      Questionary: JSON.stringify(data),
      Deleted: false,
      isDefault: false,
      Deletable: true,
      DateCreated: new Date(),
      ClientId: auth.user.ClientId,
      Weight: QWeight.current.value
    }
    if (id != undefined) {
      Questionary.Id = id;
      UpdateQuestionary(Questionary).then(x => {
        navigate("/admin/questionaries");
      })
    } else {
      SaveQuestionary(Questionary).then(x => {
        navigate("/admin/questionaries");
      })
    }
    //getQuestionaryName(NameRef.current.value);
    ElementStore.dispatch("post");
  }
  let modalClass = 'modal';
  useEffect(() => {
    console.log(props);
    //setData(props.data);
    if (previewVisible) {
      modalClass += ' show d-block';
    }
  }, [previewVisible])


  return (
    <>
      <div className="clearfix" style={{ margin: '10px', width: '70%' }}>
        {previewVisible &&
          <div className="modal show d-block" role="dialog">
            <div className="modal-dialog modal-xl" role="document">
              <div className="modal-content">
                <Col style={{ padding: "20px" }}>
                  <h3>{getQuestionaryName}</h3>

                  <ReactFormGenerator
                    onSubmit={() => { console.log(data) }}
                    //  download_path=""                 
                    //  answer_data={answers}
                    // action_name="Save"
                    // form_action="/api/form"
                    //  form_method="POST"
                    // skip_validations={true}
                    // onSubmit={this._onSubmit}                
                    data={data}
                  />
                </Col>
                <div className="modal-footer">
                  <button type="button" className="btn btn-default" data-dismiss="modal" onClick={ClosePreview}>Close</button>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
      <Row>
        <Col md={10}>
          Questionary questions weight: <strong>{data.reduce((a, b) => a + (b.weigth != undefined ? Number(b.weigth) : 0), 0).toFixed(2)}</strong>
        </Col>
      </Row>
      <Row>

        <Col md={8}>
          <FormGroup>
            <Label for="exampleEmail">
              Questinary Name
            </Label>
            <Input defaultValue={getQuestionaryName} id="questionaryName" className='m-0' placeholder='Questionary Name' innerRef={NameRef} />
          </FormGroup>
        </Col>
        <Col md={2}>
          <FormGroup>
            <Label for="exampleEmail">
              Questinary Weight
            </Label>
            <Input defaultValue={props.weight!=null?props.weight:"0.0"} id="Weight" type="number" max="1" step="0.1" className="m-0" placeholder='Questionary Weight' innerRef={QWeight} />
          </FormGroup>
        </Col>


        <Col md={2} className='text-right'>
          <button className="btn btn-primary float-right" style={{ marginRight: '10px' }} onClick={() => ShowPreivew()}>Preview Form</button>
          <button className="btn btn-primary float-right" style={{ marginRight: '10px' }} onClick={() => saveFormData()}>Save Form</button>
        </Col>
      </Row >
    </>
  )
});
export default FormBuilderPreview;