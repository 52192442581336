import React from 'react';

export const SeverityColors = (severity) => {
    // console.log(severity);
    switch (severity) {
        case 3:
            return ("#FF2B2B, #FF2B2B");
            break;
        case 1:
            return ("#FF9900, #FF9900");
            break;
        case 0:
            return ("#898989, #898989");
            break;
        case 2:
            return ("#FF9900, #F83939");
            break;
    }
}

export const SeverityName=(severity)=>{
    switch (severity) {
        case 3:
            return "CRITICAL";
            break;
        case 1:
            return "MEDIUM";
            break;
        case 0:
            return "LOW";
            break;
        case 2:
            return "HIGH";
            break;
    }
}