import React, { Component, Suspense, useEffect, useRef, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import AppRoutes from './routes/AppRoutes';
import Layout from './layouts/Layout';
import './custom.css';
import Login from './authentication/Login';
import { useAuth } from './contexts/authContext';
import { VulnerabilityProvider } from "./contexts/vulnerabilityContext";
//import * as Worker from './webworker.js';
import { ThemeContext } from './contexts/themeContext';
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { CustomNavigationClient } from "./utils/NavigationClient";
import { InteractionStatus, InteractionType, InteractionRequiredAuthError } from "@azure/msal-browser";
//import { loginRequest } from "./authConfig";
import LoaderContainer from './components/ui/LoaderContainer/LoaderContainer';
import ChangePassword from './authentication/ChangePassword';
function App({ pca }) {
  const isBrowserDefaultDark = () => window.matchMedia('(prefers-color-scheme: dark)').matches;
  const [result, setResult] = useState(null);
  const [theme, setTheme] = useState(isBrowserDefaultDark() ? 'light' : 'dark');
  const workerRef = useRef(null);
  const auth = useAuth();
  const navigate = useNavigate();
  //const navigationClient = new CustomNavigationClient(navigate);
  //pca.setNavigationClient(navigationClient);
  // const authRequest = {
  //   ...loginRequest
  // };
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      // Custom logic to handle the refresh
      // Display a confirmation message or perform necessary actions
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
  if (!auth.token && !auth.loading) {
    return <Login />
  } else
    if (auth.user != undefined && auth.user.LastLogin == undefined && !auth.loading) {
      console.log(auth);
      return <ChangePassword />

    } else
      return (
        <>


          {/* <MsalProvider instance={pca}> */}
          {/* <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={authRequest}
          loadingComponent={LoaderContainer}
        >
        </MsalAuthenticationTemplate> */}
          <ThemeContext.Provider value={{ theme, setTheme }}>
            <Layout>
              <Routes>
                {AppRoutes.map((route, index) => {
                  const { ...rest } = route;
                  return <Route key={index} {...rest} />;
                })}
              </Routes>
            </Layout>
          </ThemeContext.Provider>
          {/* </MsalProvider> */}
        </>
      );
}
export default App;
