import React, { useEffect, useMutation, useMemo, useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import Login from "../authentication/Login";

const AuthContext = React.createContext(null);

export const useAuth = () => {
    return React.useContext(AuthContext);
}
export const loginApiCall = (userName, pdw) => {
    if (!userName) { return Promise.reject("Missing Credentials") }
    return axios.post("/auth/login", { username: userName, password: pdw, name: "", email: "" })
}
export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const [token, setToken] = React.useState();
    const [loading, setLoading] = React.useState(false);
    const [redirect, setRedirect] = React.useState('');
    const [user, setUser] = React.useState();
    const [error, setError]= React.useState(null);
    //"!@3fsd123r121233"
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    useEffect(() => {  
        setLoading(true);     
        if (sessionStorage.getItem("token") != undefined) {
            axios.get("/auth/getUser", {
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem("token")
                }
            }).then(res => {
                setToken(sessionStorage.getItem("token"));
                setUser(res.data);
                setLoading(false);
                axios.defaults.headers.common = {'Authorization': `Bearer ${sessionStorage.getItem("token")}`}
                if (queryParams.get('returnUrl') != undefined || queryParams.get('returnUrl') != "/logout") {
    
                    navigate(queryParams.get('returnUrl'));
                }  else{
                    navigate("/");
                }
            });
        } else {
            setLoading(false);
            if (queryParams.get('returnUrl') != undefined) {
                if (queryParams.get('returnUrl') != "/logout") {
                    setRedirect(queryParams.get('returnUrl'));
                } 
                // else {
                //     setRedirect("/");
                // }
            }


            if (location.pathname === "/callback") {
                 console.log(queryParams.get('returnUrl'));
                if (queryParams.get('returnUrl') != undefined)
                    axios.get("/auth/callback?returnUrl=" + (queryParams.get('returnUrl') != "/logout" ? queryParams.get('returnUrl') : "/")).then(res => {
                        console.log("intra");
                        if (res.data != undefined) {
                            setLoading(false);
                            setToken(res.data.Token);
                            setUser(res.data.User);
                            setRedirect(res.data.Redirect);
                            console.log("intra:" + res.data.Token);
                            sessionStorage.setItem("token", res.data.Token);
                        }
                    });
            }

            // {
            //     axios.get("/auth/callback?url=/").then(res => {
            //         if (res.data != undefined) {
            //             setToken(res.data.Token);
            //             setRedirect(res.data.Redirect);
            //             sessionStorage.setItem("token", res.data.Token);
            //         }
            //     });

            // }

            if (location.pathname === '/login' && token) {
                if (queryParams.get('returnUrl') != undefined && queryParams.get('returnUrl') != "/logout") {

                    navigate(queryParams.get('returnUrl'));
                } else {
                    navigate(redirect ? redirect : '/');
                }
            } else if (!token) {
                
                const queryParams = new URLSearchParams(location.search);
                if (queryParams.get('returnUrl') != null)
                    if (queryParams.get('returnUrl') != "/logout")
                        navigate('/login?returnUrl=' + queryParams.get('returnUrl'));
                    else
                        navigate('/login?returnUrl=/');
                else
                    navigate('/login?returnUrl=' + location.pathname);
            }
        }
    }, [token])

    useEffect(() => {
        if (location.pathname === "/logout" && token) {
            axios.defaults.headers.common="";
            sessionStorage.removeItem("token");
            setToken(null);
            navigate("/login?returnUrl=/");
            try {
                // axios.post("/auth/logout").then(res => {
                //     if (res.data != undefined) {
                //         setToken(null);
                //         setRedirect(null);
                //         //  sessionStorage.setItem("token", res.data.Token);                  
                //         sessionStorage.removeItem("token");
                //     }
                // }).catch(err => {
                //     console.log(err);
                // });
            }
            catch (err) {
                console.log(err);
            }

        }
    }, [location])

    // const loginCall = useMutation((data) => loginApiCall(data.username, data.password), {
    //     onSuccess: token => {
    //         console.log('success', token);
    //         setToken(token.data);
    //         // I could do a settimeout here Or use the useeffect hook
    //         // setTimeout(() => navigate('/'))
    //     }
    // })

    const handleLogin = async (username, password) => {
       
        return loginApiCall(username, password).then(res => {
            sessionStorage.setItem("token", res.data.Token);
            setToken(res.data.Token);
            setUser(res.data.User);
            return {'login':"succesfull"};
            //console.log(res.data);
        }).catch(res=>{
            setError(res);
            return {'login':"failed","error":res.response.data};
        })
        
    };

    const handleLogout = () => {
        sessionStorage.removeItem("token");
        setToken(null);
        // todo: call logout api to invalidate token
    };

    const value = useMemo(() => ({
        loading: loading,
        token,
        onLogin: handleLogin,
        onLogout: handleLogout,
        user
    }), [token]);

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}
