import 'bootstrap/dist/css/bootstrap.css';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
/*import * as serviceWorkerRegistration from './serviceWorkerRegistration';*/
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from "./contexts/authContext"
/*import { PublicClientApplication, EventType } from "@azure/msal-browser";*/
//import { msalConfig } from "./authConfig";

const root = ReactDOM.createRoot(document.getElementById('root'));

//export const msalInstance = new PublicClientApplication(msalConfig);
// msalInstance.initialize().then(() => {
//   // Default to using the first account if no account is active on page load
//   if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
//     // Account selection logic is app dependent. Adjust as needed for different use cases.
//     msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
//   }

//   // Optional - This will update account state if a user signs in from another tab or window
//   msalInstance.enableAccountStorageEvents();

//   msalInstance.addEventCallback((event) => {
//     if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
//       const account = event.payload.account;
//       msalInstance.setActiveAccount(account);
//     }
//   });

root.render( 
    <BrowserRouter basename=''>
      <AuthProvider>
        <App />
      </AuthProvider>
    </BrowserRouter>
 );
//});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//serviceWorkerRegistration.registerServiceWorker();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
