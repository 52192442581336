import React, { useState } from "react";
import "./Tab.css";
import {
    Col, Row, TabContent, TabPane, Nav,
    NavItem, NavLink
} from "reactstrap";

const Tab = (props) => {
    const { buttons } = props;
    const [activeTab, setActiveTab] = useState(props.tabs[0].id);
    const updateActiveTab = (id) => {
        setActiveTab(id)
    }
    return (
        <Row>
            <Col>
                <nav className="navbar navbar-default tabContainer" role="tabs">                   
                        <ul className="nav navbar-nav navbar-left">
                            {props.tabs.map(x => {
                                return <NavItem key={`key-` + x.id}>
                                    <NavLink className={`defaultTab ` + (activeTab == x.id ? 'active' : '')} onClick={() => updateActiveTab(x.id)}>
                                        {x.name}
                                    </NavLink>
                                </NavItem>
                            })}
                        </ul>
                        {
                            buttons != undefined ?
                                <ul className="nav navbar-right buttons">
                                    {buttons.map((x, index) => {
                                        return <NavItem key={`key-` + index} >
                                            <NavLink>
                                                {x}
                                            </NavLink>
                                        </NavItem>
                                    })
                                    }</ul> : null
                        }                   
                </nav>

                <TabContent className="tabContentContainer" activeTab={activeTab}>
                    {props.children}
                </TabContent>
            </Col>
        </Row >
    )
}
export default Tab;