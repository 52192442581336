import React from 'react';
import "./SmallRating.css";
import { UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
const SmallRating = (props) => {
    const { rating, size, privisional } = props;
    //console.log("RATING "+rating + "Provisional "+privisional);
    const pClasses = [
        { id: 0, pill: "pGray", rating: "N/A" },
        { id: 1, pill: "plGreen", rating: "Great" },
        { id: 2, pill: "pdGreener", rating: "Good" },
        { id: 3, pill: "pOrange", rating: "Bad" },
        { id: 4, pill: "pRed", rating: "Critical" }
    ];
    const cRating = pClasses.filter(x => x.id == (rating ? rating : 0));
    //console.log(cRating);
    return (
        <>
            <div className={`${size != undefined ? size : ""}`}>
                <div className={`pill ${cRating[0].pill} ${size != undefined ? size : ""}`}></div>{cRating[0].rating}  
                {privisional>=0 && privisional!=1 ? <>
                <span id={"sratingToolTip-" + rating} className="right" style={{
                    display: "inline-flex",
                    height: "100%",
                    flexWrap: "nowrap",
                    alignItems: "center",
                    justifyContent: "center"
                }}><FontAwesomeIcon color="orange" size="xl" icon={faExclamationCircle} />
                    <UncontrolledTooltip
                        placement="bottom"
                        target={"sratingToolTip-" + rating}
                    >
                      Provisional Rating; Questionary not yet Approved
                    </UncontrolledTooltip>
                </span>  </> : null}             
            </div>
            
        </>
    )

}
export default SmallRating;