import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Button, Col, Row, TabPane, UncontrolledTooltip } from 'reactstrap';
import Card from "../../components/ui/Card/Card";
import SupplierCounter from '../../components/ui/SupplierCounter/SupplierCounter';
import CustomerRatingScore from '../../components/ui/CustomerRatingScore/CustomerRatingScore';
import CustomerRatingNScore from '../../components/ui/CustomerRatingNScore/CustomerRatingNScore';
import Tab from '../../components/ui/Tab/Tab';
import DynamicTable from '../../components/ui/Table/Table';
import SmallRating from '../../components/ui/SmallRating/SmallRating';
import DCSDoughnut from '../../components/ui/Charts/Doughnut/Doughnut';
import LoaderContainer from '../../components/ui/LoaderContainer/LoaderContainer';
import { SeverityColors, SeverityName } from '../../components/ui/Helpers/Colors';
//import { useVulnerabilityContext } from '../../contexts/vulnerabilityContext';
import { Suppliers } from '../../data/suppliers';
import { Link } from 'react-router-dom';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HeatMapGrid } from 'react-heatmap-grid';
import QuestionaryCalculator from '../../utils/questionaryCalculator';
import DataTable from '../../components/ui/Table/DataTable';
import { useNavigate } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { useAuth } from '../../contexts/authContext';
import { getRatings } from '../../data/administration';
import RatingFormulaCalculator from '../../utils/ratingFormulaCalculator';
import { PostureRatingSupplier, PostureRatingSuppliers } from '../../utils/ratingCalculator';
import { GetQuestionaries } from '../../data/questionary';
const Home = (props) => {
    const [Vulnerabilities, setVulnerabilities] = useState(null);
    const [supplierData, setSuppliers] = useState(null);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [vulerabilitiesLoading, setVulLoading] = useState(false);
    const [questionariesSent, setQuestionariesSent] = useState(null);
    const [questionariesAwaitingApproval, setQuestionariesAwaitingApproval] = useState(null);
    const [avgScore, setAvgScore] = useState(null);
    const [risk, setRisk] = useState(null);
    const [formula, setFormula] = useState(null);
    const [generalRating, setGeneralRating] = useState(null);
    const auth = useAuth();
    const navigate = useNavigate();
    //useMemo(() => {

    //    // let newData = [];
    //    // if (supplierData != null) {
    //    //   supplierData.map(supplier => {
    //    //     supplier.Assets.map(asset => {
    //    //       if (asset.IsOfInterest) {
    //    //         asset.AssetScans.map(scan => {
    //    //           if (scan.Risk_Level >1 && scan.Ignore == 0) {
    //    //             newData.push({
    //    //               id: scan.Id,
    //    //               SupplierId: supplier.Id,
    //    //               severity: scan.Risk_Level,
    //    //               Supplier: supplier.SupplierName,
    //    //               Asset: asset.Name,
    //    //               CVE: scan.CVE,
    //    //               Risk_Description: scan.Risk_Description,
    //    //               Description: scan.Description
    //    //             })
    //    //           }
    //    //         })
    //    //       }
    //    //     })
    //    //   })
    //    // }
    //    // newData = newData.sort((a, b) => b.severity - a.severity);
    //    // if (newData.length > 0) {
    //    //   setVulnerabilities(newData.slice(0, 6))
    //    // }
    //    // setVulLoading(false);

    //}, [supplierData])
    const Title = "Dashboard";   
    useMemo(() => {
        setVulLoading(true);
        GetQuestionaries(auth.user.ClientId).then(rez => {
            //console.log(rez);
            const qsent = rez.data.filter(x => x.u.Status == "Sent");
            const qawaitingapproval = rez.data.filter(x => x.u.Status == "Responded");
            setQuestionariesSent(qsent);
            setQuestionariesAwaitingApproval(qawaitingapproval);
        })
        Suppliers(null, auth.user.ClientId).then(res => {
            setSuppliers(res.data);
        });
    }, [auth.user.ClientId])

    const toggle = () => {
        setTooltipOpen(!tooltipOpen);
    }

    let t = 0;
    const initialCVESort = [{ columnKey: 'severity', direction: 'DESC' }];
    const CVEColumns = [
        {
            key: 'id',
            id:"supplier_asset",
            Header:'',
            name: 'Supplier',
            accesor: "Asset",
            resizable: false,
            defaultFlex: 2,
            Cell: ({ row }) => {
                return (<div>
                    <h6>
                        {auth.user.RolePermission.Role.PermissionActions.filter(x => x.ActionP.Name == "View" && x.Permission.Name == "Suppliers").length > 0 ?
                            <Link to={"portfolio/suppliers/" + row.original.Asset.SupplierId + "/assets"} className="no-markings">{row.original.Asset.Suppliers.Name}</Link> : row.original.Asset.Suppliers.Name}
                        {/* <a href={"portfolio/suppliers/" + params.row.SupplierId} target="_blank" className="no-markings">{params.row.Supplier}</a> */}
                    </h6>
                    <span>{row.original.Asset.Name}</span>
                </div>)
            },
            render: ({ data }) => {
                return (<div>
                    <h6>
                        {auth.user.RolePermission.Role.PermissionActions.filter(x => x.ActionP.Name == "View" && x.Permission.Name == "Suppliers").length > 0 ?
                            <Link to={"portfolio/suppliers/" + data.Asset.SupplierId + "/assets"} className="no-markings">{data.Asset.Suppliers.Name}</Link> : data.Asset.Suppliers.Name}
                        {/* <a href={"portfolio/suppliers/" + params.row.SupplierId} target="_blank" className="no-markings">{params.row.Supplier}</a> */}
                    </h6>
                    <span>{data.Asset.Name}</span>
                </div>)
            },
            // cellClass: "normalLineHeight"
        },
        {
            key: 'severity',
            id: "severity",
            name: 'Risk_Level',
            Header: '',
            accesor:"Risk_Level",
            defaultFlex: 1,
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            resizable: false,
            Cell: ({ row }) => {
                return (
                    <span className="label" id={`severity-` + row.original.Id} style={{ backgroundImage: "linear-gradient(to bottom right, " + SeverityColors(row.original.Risk_Level) + ")", display: "flex", justifyContent: "center", padding: "5px", borderRadius: "10px", width: "100%" }}>
                        {row.original.Risk_Description != "" ?
                            <UncontrolledTooltip target={`severity-` + row.original.Id} >
                                {row.original.Risk_Description}
                            </UncontrolledTooltip>
                            : null}
                        {SeverityName(row.original.Risk_Level)}
                    </span>
                )
            },
            render: ({ data }) => {
                //console.log(data);
                return (
                    <span className="label" id={`severity-` + data.Id} style={{ backgroundImage: "linear-gradient(to bottom right, " + SeverityColors(data.Risk_Level) + ")", display: "flex", justifyContent: "center", padding: "5px", borderRadius: "10px", width: "100%" }}>
                        {data.Risk_Description != "" ?
                            <UncontrolledTooltip target={`severity-` + data.Id} >
                                {data.Risk_Description}
                            </UncontrolledTooltip>
                            : null}
                        {SeverityName(data.Risk_Level)}
                    </span>
                )

            }
        }
    ];

    const RefreshAnswer = () => {

    }
    const QuestioanryColumns = [
        {
            Header: 'Supplier',
            name: 'Supplier',
            defaultWidth: 275,
            Cell: ({ row }) => {
                return (<div><h6>
                    {
                        auth.user.RolePermission.Role.PermissionActions.filter(x => x.ActionP.Name == "View" && x.Permission.Name == "Suppliers").length > 0 ?
                            <Link to={"portfolio/suppliers/" + row.original.u.SupplierId} className="no-markings">{row.original.uir.Name}</Link> : row.original.uir.Name
                    }
                </h6>
                </div>)
            },
            render: ({ data }) => {
                // console.log(params);
                return (<div><h6>
                    {
                        auth.user.RolePermission.Role.PermissionActions.filter(x => x.ActionP.Name == "View" && x.Permission.Name == "Suppliers").length > 0 ?
                            <Link to={"portfolio/suppliers/" + data.u.SupplierId} className="no-markings">{data.uir.Name}</Link> : data.uir.Name
                    }                   
                </h6>
                </div>)
            },

        },
        {
            Header: 'Status',
            name: 'Status',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            resizable: false,
            Cell: ({ row }) => {
                return (row.original.u.Status)
            },
            render: ({ data }) => {
                // console.log(params);
                return (data.u.Status )
            },
        },
        {
            Header: 'DueDate',
            name: 'DueDate',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            resizable: false,
            Cell: ({ row }) => {
                return new Date(row.original.u.DueDate).toLocaleDateString('en-US');
            },
            render: ({ data }) => {
                return new Date(data.u.DueDate).toLocaleDateString('en-US');
            }
        },
        {
            Header: 'DateSent',
            name: 'DateSent',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            resizable: false,
            Cell: ({ row }) => {
                return new Date(row.original.u.DateSent).toLocaleDateString('en-US');
            },
            render: ({ data }) => {
                return new Date(data.u.DateSent).toLocaleDateString('en-US');
            }
        }
    ];
    const AwaitingApprovalColumns = [
        {
            Header: 'Supplier',
            name: 'Supplier',          
            Cell: ({ row }) => {
                return (<div><h6>
                    {auth.user.RolePermission.Role.PermissionActions.filter(x => x.ActionP.Name == "View" && x.Permission.Name == "Suppliers").length > 0 ?
                        <Link to={"portfolio/suppliers/" + row.original.u.SupplierId} className="no-markings">{row.original.uir.Name}</Link> : row.original.uir.Name}
                    {/* <a href={"portfolio/suppliers/" + params.row.SupplierId} target="_blank" className="no-markings">{params.row.Supplier}</a> */}
                </h6>
                </div>)
            },
            defaultWidth: 275,
            render: ({ data }) => {
                // console.log(params);
                return (<div><h6>
                    {auth.user.RolePermission.Role.PermissionActions.filter(x => x.ActionP.Name == "View" && x.Permission.Name == "Suppliers").length > 0 ?
                        <Link to={"portfolio/suppliers/" + data.u.SupplierId} className="no-markings">{data.uir.Name}</Link> : data.uir.Name}
                    {/* <a href={"portfolio/suppliers/" + params.row.SupplierId} target="_blank" className="no-markings">{params.row.Supplier}</a> */}
                </h6>
                </div>)
            },
            cellClass: "normalLineHeight"
        },
        {
            Header: 'Questionary',
            name: 'Questionary',           
            Cell: ({ row }) => {
                return (<div><h6>
                    {auth.user.RolePermission.Role.PermissionActions.filter(x => x.ActionP.Name == "View" && x.Permission.Name == "Suppliers").length > 0 ?
                        <Link to={"portfolio/suppliers/" + row.original.u.SupplierId} className="no-markings">{row.original.u.Questionary.Name}</Link> : row.original.Supplier}
                    {/* <a href={"portfolio/suppliers/" + params.row.SupplierId} target="_blank" className="no-markings">{params.row.Supplier}</a> */}
                </h6>
                </div>)
            },
            render: ({ data }) => {
                // console.log(data);
                return (<div><h6>
                    {auth.user.RolePermission.Role.PermissionActions.filter(x => x.ActionP.Name == "View" && x.Permission.Name == "Suppliers").length > 0 ?
                        <Link to={"portfolio/suppliers/" + data.u.SupplierId} className="no-markings">{data.u.Questionary.Name}</Link> : data.Supplier}
                    {/* <a href={"portfolio/suppliers/" + params.row.SupplierId} target="_blank" className="no-markings">{params.row.Supplier}</a> */}
                </h6>
                </div>)
            },
            cellClass: "normalLineHeight"
        },
        {
            Header: 'Status',
            name: 'Status',            
            defaultWidth: 100,
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            showColumnMenuTool: false,
            resizable: false,
            Cell: ({ row }) => {
                return row.original.u.Status
            },
            render: ({ data }) => {
                return data.u.Status
            }
        },
        {
            Header: 'DueDate',
            name: 'DueDate',           
            defaultWidth: 100,
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            resizable: false,
            Cell: ({ row }) => {
                return new Date(row.original.u.DueDate).toLocaleDateString('en-US');
            },
            render: ({ data }) => {
                //console.log(data);
                return new Date(data.u.DueDate).toLocaleDateString('en-US');
            }
        },
        {
            Header: 'DateSent',
            name: 'DateSent',           
            defaultWidth: 100,
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            resizable: false,
            Cell: ({ row }) => {
                return new Date(row.original.u.DateSent).toLocaleDateString('en-US');
            },
            render: ({ data }) => {
                return new Date(data.u.DateSent).toLocaleDateString('en-US');
            }
        }
    ];
    const columns = [
        {
            Header: 'Supplier Name',
            name: 'supplierName',          
            sortable: true,
            defaultWidth: 300,
            Cell: ({ row }) => {
                return <>{auth.user.RolePermission.Role.PermissionActions.filter(x => x.ActionP.Name == "View" && x.Permission.Name == "Suppliers").length > 0 ? <Link to={"portfolio/suppliers/" + row.original.id} className="no-markings">{row.original.supplierName}</Link> : row.original.supplierName}</>
            },
            render: ({ data }) => {
                //console.log(params)

                return <>{auth.user.RolePermission.Role.PermissionActions.filter(x => x.ActionP.Name == "View" && x.Permission.Name == "Suppliers").length > 0 ? <Link to={"portfolio/suppliers/" + data.id} className="no-markings">{data.supplierName}</Link> : data.supplierName}</>
            }
        },
        {
            Header: 'Rating',
            name: 'rating',
            accessor: 'rating',
            sortable: true,
            Cell: ({ row }) => {
                return <strong>
                    <SmallRating rating={row.original.rating} />
                </strong>
            },
            render: ({ data }) => {
                //console.log(params)
                return <strong>
                    <SmallRating rating={data.rating} />
                </strong>
            }
        },
        {
            Header: 'Date Created',
            name: 'dateCreated',
            accessor: 'dateCreated',
            sortable: true,
            type: 'date',
            Cell: ({ row }) => {
                return row.original.dateCreated.split("T")[0];
            },
            render: ({ data }) => {
                return data.dateCreated.split("T")[0];
            }
            // valueGetter: ({ value }) => value && new Date(value),
        }
    ];
    useEffect(() => {
        if (supplierData != null) {
            setGeneralRating(supplierData.Rating.Rating);
            setAvgScore(supplierData.Rating.Score);
            setVulnerabilities(supplierData.Vulnerabilities);
            setVulLoading(false);
        }        
    }, [supplierData])

   
    const Tabs = [
        { name: "Top highest risk", id: "!", title: "Top 10 highest risk suppliers", columns: columns, rows: risk },
        { name: "Awaiting approval", id: "2", title: "Completed questionaries awaiting approvail", columns: AwaitingApprovalColumns, rows: questionariesAwaitingApproval },
        { name: "Questionaries", id: "3", title: "Sent questionaries", columns: QuestioanryColumns, rows: questionariesSent }
    ];
    const Scores = [
        { score: "N/A", rating: "N/A", color: "#979797" },
        { score: "A", rating: "GREAT", color: "#1BC000" },
        { score: "B", rating: "GOOD", color: "#02830F" },
        { score: "C", rating: "BAD", color: "#F97316" },
        { score: "D", rating: "CRITICAL", color: "#EF4444" },

    ];
    
    const onSelectionChange = useCallback((selected) => {
        // console.log(selected.data);
        if (auth.user.RolePermission.Role.PermissionActions.filter(x => x.ActionP.Name == "View" && x.Permission.Name == "Suppliers").length > 0)
            navigate("/portfolio/suppliers/" + selected.data.Asset.SupplierId + "/assets");

    }, [])
    return (
        <>
            {/* <AuthenticatedTemplate> */}
            {supplierData != null ?
                <>
                    <Row>
                        <Col md="12" xs="12">
                            <h3 className="dcs-Title bold">{Title}</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="5" xs="12" >
                            <Col>
                                <Card>
                                    <Row className="customerGeneralInfo">
                                        <SupplierCounter number={supplierData.length > 0 ? supplierData.NewSuppliers : 0} percentage={100} />
                                        <Col md="4" xs="12" className='supplierCounter'>
                                            {generalRating != null ?
                                                <CustomerRatingScore gRating={generalRating != null ? Scores[generalRating].score : Scores[0].score} /> : null
                                            }
                                        </Col>
                                        {avgScore != null ?
                                            <CustomerRatingNScore rating={Math.round(avgScore)} /> : null}
                                    </Row>
                                </Card>
                            </Col>
                            <Row>
                                <Col md="6" xs="12">
                                    <Card>
                                        <h5 className='dcs-Title'>Supplier Score Distribution</h5>
                                        <Row>
                                            <Col md="12">
                                                {supplierData != null ?
                                                    <DCSDoughnut supplierData={supplierData.Rating.SupplierRatings} /> : <LoaderContainer />}
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col md="6" xs="12" style={{ paddingLeft: 0 }}>
                                    <Card>
                                        <h5 className='dcs-Title'>Latest Vulnerabilities</h5>
                                        <Row>
                                            <Col md="12">
                                                {!vulerabilitiesLoading ?                                                   
                                                    //<DataTable columns={CVEColumns} enableSelection={true} showCellBorders="none" rowHeight={80} showZebraRows={false} showHeader={false} defaultLimit={10} data={Vulnerabilities != null ? Vulnerabilities : []} id="assets" onSelectionChange={onSelectionChange} />
                                                    <DynamicTable columns={CVEColumns} enableSelection={true} showCellBorders="none" rowHeight={80} showZebraRows={false} showHeader={false} defaultLimit={10} data={Vulnerabilities != null ? Vulnerabilities : []} id="assets" onSelectionChange={onSelectionChange} />
                                                    // <DynamicTable showTitle={false} className={"rdg-header-row-none"} rowHeight={65} defaultsortColumns={initialCVESort} PageSize={6} pagination={true} headerHeight={0} rows={Vulnerabilities} columns={CVEColumns} />
                                                    : <LoaderContainer />}
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        <Col md="5" xs="12">
                            <Card>
                                <Tab tabs={Tabs}>
                                    {Tabs.map(x => {
                                        return (
                                            <TabPane tabId={x.id} key={x.id}>
                                               
                                                <DynamicTable rowHeight={50} columns={x.columns} showCellBorders={false} title={x.title} showZebraRows={false} showHeader={true} data={x.rows != null ? x.rows : []} virtualizeColumns={false} pagination={x.rows != null ? x.rows.length > 10 ? true : false : false} defaultLimit={10} />
                                            </TabPane>
                                        )
                                    })}
                                </Tab>
                            </Card>
                        </Col>
                        <Col md="2" xs="12" className="disabled">
                            <Card padding="0">
                                <img src="/images/image1.png" />
                                <h5 className='text-center'>Request pentesting service</h5>
                            </Card>
                        </Col>
                    </Row>
                </> : <LoaderContainer />}
            {/* </AuthenticatedTemplate> */}
            {/* <UnauthenticatedTemplate>
        
          <center>Please sign-in to see your profile information.</center>
        
      </UnauthenticatedTemplate> */}
        </>
    )
}
export default Home;