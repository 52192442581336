import React, { Component } from 'react';
import DynamicTable from '../components/ui/Table/Table';
import SmallRating from '../components/ui/SmallRating/SmallRating';
import { Button } from 'reactstrap';

export class FetchData extends Component {
  static displayName = FetchData.name;

  constructor(props) {
    super(props);
    this.state = { forecasts: [], loading: true };
  }

  componentDidMount() {
    this.populateWeatherData();
  }

  static renderForecastsTable(forecasts) {
    return (
      <table className='table table-striped' aria-labelledby="tabelLabel">
        <thead>
          <tr>
            <th>Date</th>
            <th>Temp. (C)</th>
            <th>Temp. (F)</th>
            <th>Summary</th>
          </tr>
        </thead>
        <tbody>
          {forecasts.map(forecast =>
            <tr key={forecast.date}>
              <td>{forecast.date}</td>
              <td>{forecast.temperatureC}</td>
              <td>{forecast.temperatureF}</td>
              <td>{forecast.summary}</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  render() {
    let contents = this.state.loading
      ? <p><em>Loading...</em></p>
      : FetchData.renderForecastsTable(this.state.forecasts);
      const columns = [
        {
          key:"id",
          name:"ID"
        },
        {
          key: 'supplierName',
          name: 'Supplier Name',
          sortable: true
        },
        {
          key: 'rating',
          name: 'Rating',
          sortable: true,
          renderCell: (params) => {
            //console.log(params)
            return <strong>
              <SmallRating rating={params.row.rating} />
            </strong>
          }
        },
        {
          key: 'dateCreated',
          name: 'Date Created',
          sortable: true,
          valueGetter: ({ value }) => value && new Date(value),
        },
        {
          key: 'nothing',
          name: '',
          description: 'This column has a value getter and is not sortable.',
          renderCell: (params) => (
            <strong>
              <Button className="DefaultButton">
                Review
              </Button>
            </strong>
          )
        },
      ];
    
      const rows = [
        { id: 1, supplierName: 'Lorem ipsum dolor sit amet,', rating: '1', dateCreated: "2023-09-15" },
        { id: 2, supplierName: 'Lorem ipsum dolor sit amet,', rating: '2', dateCreated: "2023-09-15" },
        { id: 3, supplierName: 'Lorem ipsum dolor sit amet,', rating: '3', dateCreated: "2023-09-15" },
        { id: 4, supplierName: 'Lorem ipsum dolor sit amet,', rating: '4', dateCreated: "2023-09-15" },
        { id: 5, supplierName: 'Lorem ipsum dolor sit amet,', rating: '1', dateCreated: "2023-09-15" },
        { id: 6, supplierName: 'Lorem ipsum dolor sit amet,', rating: '2', dateCreated: "2023-09-15" },
        { id: 7, supplierName: 'Lorem ipsum dolor sit amet,', rating: '2', dateCreated: "2023-09-15" },
        { id: 8, supplierName: 'FraLorem ipsum dolor sit amet,nces', rating: '3', dateCreated: "2023-09-15" },
        { id: 9, supplierName: 'Lorem ipsum dolor sit amet,', rating: '2', dateCreated: "2023-09-15" },
      ];
    return (
      <div>
        <h1 id="tabelLabel" >Weather forecast</h1>
        <p>This component demonstrates fetching data from the server.</p>
        {contents}
        <DynamicTable rows={rows} columns={columns} rowSelect={true}  pagination={false} />
      </div>
    );
  }

  async populateWeatherData() {
    const response = await fetch('weatherforecast');
    const data = await response.json();
    this.setState({ forecasts: data, loading: false });
  }
}
