import React, { useState } from 'react';
import "./BusinessImpactBig.css";
const BusinessImpactBig = (props) => {
    const { value, callBack } = props;
    const [selected, SetSelected] = useState(value != undefined ? value : 0);
    const BI = ["Minimal", "Minor", "Moderate", "Significant", "Severe"];
    const _OnClick = (index) => {
        SetSelected(index);
        if (callBack != undefined) {
            callBack(index);
        }
    }
    return (
        <div className="businessImpactBig">
            {
                BI.map((x, index) =>
                (
                    <div className="dcs-bi-container" key={`bi-` + index}>
                        <div className={`dcs-bi-cell ${index <= selected ? "colored" : null}`} >
                            <p className={`dcs-bi-cell-title ${selected == index ? "dcs-bi-cell-title-nothidden" : null}`}>{x}</p>
                            <div className="Dcs-bi-box" onClick={() => _OnClick(index)}></div>
                        </div>
                    </div>
                )
                )
            }
        </div>
    )

}
export default BusinessImpactBig;