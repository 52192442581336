import React, { useEffect, useState, useRef, useMemo } from "react";
import { Suppliers } from "../../data/suppliers";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { UncontrolledAccordion, Button, Col, Row, UncontrolledTooltip, AccordionBody, AccordionHeader, AccordionItem, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledAlert } from "reactstrap";
import Card from "../../components/ui/Card/Card";
import SmallRating from "../../components/ui/SmallRating/SmallRating";
import BusinessImpact from "../../components/ui/BusinessImpact/BusinessImpact";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis, faEarthAmericas, faSync } from '@fortawesome/free-solid-svg-icons';
import CustomerRatingScore from "../../components/ui/CustomerRatingScore/CustomerRatingScore";
import "./Supplier.css"
import DynamicTable from "../../components/ui/Table/Table";
import { Registry, ReactFormGenerator } from 'react-form-builder2';

import DcsToggle from "../../components/ui/Designer/Toggle/DcsToggle";
import QuestionaryCalculator from "../../utils/questionaryCalculator";
import { ApproveQuestionary, RejectQuestionary } from "../../data/questionary";
import Modal from "../../components/ui/Modal/Modal";
import DataTable from "../../components/ui/Table/DataTable";
import { useAuth } from "../../contexts/authContext";
import { getRatings } from "../../data/administration";
import LoaderContainer from "../../components/ui/LoaderContainer/LoaderContainer";
import RatingFormulaCalculator from "../../utils/ratingFormulaCalculator";
import { PostureRatingSupplier } from "../../utils/ratingCalculator";
import DCSPill from "../../components/ui/Pill/Pill";

const SupplierPage = (props) => {

    const [supplier, SetSupplier] = useState(null);
    const [hasChanged, setHasChanged] = useState(false);
    const [ratingData, setRatingData] = useState(null);
    const [readableIssues, setReadableIssues] = useState(null);
    const [visibleIssueModal, setVisibleIssueModal] = useState(false);
    const [questioanryRating, setQuestionarRating] = useState(0);
    const { id } = useParams();
    const [questionary, setQuestionary] = useState(null);
    const [formula, setFormula] = useState(null);
    const auth = useAuth();
    const navigate = useNavigate();
    const ModalRef = useRef();
    const c = Registry.get("DCSToggle");
    if (c == undefined) {
        Registry.register('DCSToggle', DcsToggle);
    }

    if (id == undefined) {
        navigate("/portfolio");
    }

    useEffect(() => {
        Suppliers(id, auth.user.ClientId).then(x => {
            //console.log(x.data.Rating);
            setRatingData(x.data.Rating);
            SetSupplier(x.data.NewSuppliers);
        });


    }, [hasChanged]);

    useMemo(() => {
        if (supplier != null) {
            let iss = [];
            supplier.Assets.map(x => {
                if (x.IsOfInterest) {
                    if (x.AssetScans.length > 0) {
                        let rs = x.AssetScans.filter(val => val.Risk_Level >= 2 && val.Ignore == 0 && val.ScanType.indexOf("DarkWeb") == -1);
                        if (rs.length > 0) {
                            iss.push({ issues: rs, name: x.Name });
                        }

                    } else { return 0 }
                }
            });
            supplier.Assets.map(x => {
                if (x.IsOfInterest) {
                    if (x.AssetScans.length > 0) {
                        let rs = x.AssetScans.filter(val => val.ScanType.indexOf("DarkWeb") != -1)
                        if (rs.length > 0) {
                            iss.push({ issues: rs, name: x.Name });
                        }
                    } else { return 0 }
                }
            });

            //console.log(Issues);
            if (iss.length > 0) {
                console.log(iss);
                setReadableIssues(iss);
            }
        }
    }, [supplier])
    useEffect(() => {
        getRatings(auth.token, auth.user.ClientId).then(rez => {
            if (rez.data != undefined) {
                //console.log(rez.data);
                setFormula(rez.data);
            }
        })
    }, [])
    const [open, setOpen] = useState('');
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };
    const toggleIssueModal = () => {
        setVisibleIssueModal(!visibleIssueModal);
    }

    const CalculateIssues = () => {

        let Issues = supplier.Assets.map(x => {
            if (x.IsOfInterest) {
                if (x.AssetScans.length > 0) {
                    return x.AssetScans.reduce((acc, val) => val.Risk_Level >= 2 && val.Ignore == 0 && val.ScanType.indexOf("DarkWeb") == -1 ? acc + 1 : acc, 0);
                } else { return 0 }
            }
        });
        let DarkWebIssues = supplier.Assets.map(x => {
            if (x.IsOfInterest) {
                if (x.AssetScans.length > 0) {
                    return x.AssetScans.reduce((acc, val) => val.ScanType.indexOf("DarkWeb") != -1 ? acc + 1 : acc, 0);
                } else { return 0 }
            }
        });

        //console.log(Issues);

        Issues = Issues.filter(x => x !== undefined);
        DarkWebIssues = DarkWebIssues.filter(x => x !== undefined);

        return Issues.reduce((acc, val) => acc + val, 0) + DarkWebIssues.reduce((acc, val) => acc + val, 0);
    }
    const CalculateQuestionaryRating = () => {
        if (ratingData.SupplierRatings[0].QuestionaryRating != -1) {
            const q = Math.floor(4 - ((ratingData.SupplierRatings[0].QuestionaryRating - 0) * (4 - 1) / (100 - 0)));
            return q;
        }
        return 0;
    }
    const CalculateRating = () => {
        // let positionRating = 0;
        // let assetBusinessImpact = 0;
        const Scores = [
            { score: "N/A", rating: "N/A", color: "#979797" },
            { score: "A", rating: "GREAT", color: "#1BC000" },
            { score: "B", rating: "GOOD", color: "#02830F" },
            { score: "C", rating: "BAD", color: "#F97316" },
            { score: "D", rating: "CRITICAL", color: "#EF4444" },
        ];

        // if (supplier.Questionaries == undefined)
        //     supplier.Questionaries = [];
        // let rating = PostureRatingSupplier(supplier, formula);
        // console.log(rating);
        if (ratingData.Rating.QuestionaryRating != -1) {
            return Scores[ratingData.Rating].score;
        } else
            return Scores[0].score;


    }
    const CalculateCyberPosture = () => {
        // if (supplier.Questionaries == undefined)
        //     supplier.Questionaries = [];
        // let rating = PostureRatingSupplier(supplier, formula);

        // let positionRating = 0;
        // let a = supplier.Assets.map(x => {
        //     if (x.IsOfInterest) {
        //         if (x.AssetScans.length > 0) {
        //             return x.AssetScans.reduce((acc, val) => acc + val.Risk_Level, 0);
        //         } else { return 0 }
        //     }
        // });
        // //Posture Calculation
        // a = a.filter(x => x !== undefined);
        // positionRating = a.reduce((acc, val) => acc + val, 0) / a.length;
        // //positionRating = positionRating < 1 ? 100 : positionRating;
        // // console.log("POSITIONAL " + positionRating);      

        const PostureRating = ratingData.SupplierRatings[0].Rating.PostureRating// Math.floor(4 - ((ratingData.SupplierRatings[0].Rating.PostureRating - 0) * (4 - 1) / (100 - 0)));

        //console.log("PostureRating " + PostureRating);
        return PostureRating;
    }

    const CalculateDNS = () => {
        let issues = 100;
        const dnsr = supplier.Assets.map(asset => {
            let DNSRecord = 0;
            asset.AssetScans.map(x => {
                if (x.Description != undefined) {
                    if (x.Description.indexOf("DNS") != -1 || x.Description.indexOf("DMARC") != -1 || x.Description.indexOf("SPF") != -1 || x.Description.indexOf("DKIM") != -1) {
                        DNSRecord++;
                        if (x.Risk_Level >= 2 && x.Ignore == 0) issues--;
                    }
                }
            })
            return DNSRecord;
        }).reduce((acc, val) => acc + val, 0)
        return { key: dnsr, value: issues };
    }
    const CalculateCORS = () => {
        let issues = 100;
        const dnsr = supplier.Assets.map(asset => {
            let DNSRecord = 0;
            asset.AssetScans.map(x => {
                if (x.Description != undefined) {
                    if (x.Description.indexOf("CORS") != -1) {
                        DNSRecord++;
                        if (x.Risk_Level >= 2 && x.Ignore == 0) issues--;
                    }
                }
            })
            return DNSRecord;
        }).reduce((acc, val) => acc + val, 0)
        return { key: dnsr, value: issues };
    }
    const CalculateVulnerabilities = () => {
        let issues = 100;
        let totalscans = 0;
        let dnsr = supplier.Assets.map(asset => {
            const ary = asset.AssetScans.map(x => {
                if (x.Description != undefined) {
                    totalscans++;
                    if (x.Description.indexOf("Vulnerabilities found") != -1) {
                        if (x.Risk_Level >= 2 && x.Ignore == 0) return x.Description;

                    }
                }

            });
            return ary.flat();
        });
        // console.log(dnsr.flat());
        dnsr = Array.from(new Set(dnsr.flat()));
        dnsr = dnsr.filter(item => item !== undefined);
        // console.log(dnsr);
        return { key: totalscans, value: issues - dnsr.length };
    }
    const CalculateNS = () => {
        let issues = 100;
        const dnsr = supplier.Assets.map(asset => {
            let DNSRecord = 0;
            asset.AssetScans.map(x => {
                if (x.Description != undefined) {
                    if (x.Vul_Description.indexOf("An NS") != -1) {
                        DNSRecord++;
                        if (x.Risk_Level >= 2 && x.Ignore == 0) issues--;
                    }
                }

            })
            return DNSRecord;
        }).reduce((acc, val) => acc + val, 0)
        return { key: dnsr, value: issues };
    }
    const CalculateEmail = () => {
        let issues = 100;
        const dnsr = supplier.Assets.map(asset => {
            let DNSRecord = 0;
            asset.AssetScans.map(x => {
                if (x.Description != undefined) {
                    if (x.Description.indexOf("MX") != -1 || x.Description.indexOf("mail") != -1 || x.Description.indexOf("POP") != -1 || x.Description.indexOf("SMTP") != -1) {
                        DNSRecord++;
                        if (x.Risk_Level >= 2 && x.Ignore == 0) issues--;
                    }
                }

            })
            return DNSRecord;
        }).reduce((acc, val) => acc + val, 0)
        return { key: dnsr, value: issues };
    }
    const CalculateDarkWeb = (stype) => {
        let outP = 0;
        const dnsr = supplier.Assets.map(asset => {
            asset.AssetScans.map(x => {
                if (x.ScanType == stype) {
                    outP += x.Risk_Level;
                }
            })
        });
        return outP;
    }
    const CalculateWeb = () => {
        let issues = 100;
        const dnsr = supplier.Assets.map(asset => {
            let DNSRecord = 0;
            asset.AssetScans.map(x => {
                if (x.Description != undefined) {
                    if (x.Description.indexOf("SSL") != -1) {
                        DNSRecord++;
                        if (x.Risk_Level >= 2 && x.Ignore == 0) issues--;
                    }
                }
            })
            return DNSRecord;
        }).reduce((acc, val) => acc + val, 0)
        return { key: dnsr, value: issues };
    }

    const CalculateSQL = () => {
        let issues = 100;
        const dnsr = supplier.Assets.map(asset => {
            let DNSRecord = 0;
            asset.AssetScans.map(x => {
                if (x.Description != undefined) {
                    if (x.Description.indexOf("SQL") != -1) {
                        DNSRecord++;
                        if (x.Risk_Level >= 2 && x.Ignore == 0) issues--;
                    }
                }

            })
            return DNSRecord;
        }).reduce((acc, val) => acc + val, 0)
        return { key: 100 - dnsr * issues / 100, value: issues };
    }
    const CalculateCSS = () => {
        let issues = 100;
        const dnsr = supplier.Assets.map(asset => {
            let DNSRecord = 0;
            asset.AssetScans.map(x => {
                if (x.Description != undefined) {
                    if (x.Description.indexOf("Cross-Site") != -1) {
                        DNSRecord++;
                        if (x.Risk_Level >= 2 && x.Ignore == 0) issues--;
                    }
                }

            })
            return DNSRecord;
        }).reduce((acc, val) => acc + val, 0)
        return { key: dnsr, value: issues };
    }
    const RefreshAnswer = () => {

    }
    const RejectAnswer = (id) => {       
        SetSupplier(null);
        RejectQuestionary(id).then(x => {
            setHasChanged(true);
        })


    }
    const ShowForm = (id) => {
        setQuestionary(supplier.QuestionarySents.filter(x => x.QuestionaryId == id)[0]);
    }
    const ApproveAnswer = (Id) => {
        SetSupplier(null);
        ApproveQuestionary(Id).then(x => {
            setHasChanged(true);
        });
    }
    const issueModalRef = useRef();
    const QuestioanryColumns = [
        {
            Header: 'Questionary',
            name: 'Supplier',
            accessor: 'Supplier',
            defaultFlex: 2,
            Cell: ({ row }) => {
                if (row.original.Response !== undefined) {
                    return (<div>
                        <strong>
                            <a onClick={() => ShowForm(row.original.Questionary.Id)} className="no-markings"> {row.original.Questionary.Name}</a>                            
                        </strong>
                    </div>)
                } else {
                  return  <strong>
                        {row.original.Questionary.Name}
                    </strong>
                }
            },
            render: ({ data }) => {
                //console.log(data);
                return (<div><h6>
                    <a onClick={() => ShowForm(data.Questionary.Id)} className="no-markings"> {data.Questionary.Name}</a>                    
                </h6>
                </div>)
            },
            cellClass: "normalLineHeight"
        },
        {
            Header: 'Status',
            name: 'Status',
            accessor: 'Status',
            defaultFlex: 1,
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            resizable: false,
            // renderCell: (params) => (
            //   <span className="label" id={`severity-` + params.row.id} style={{ backgroundImage: "linear-gradient(to bottom right, " + SeverityColors(params.row.severity) + ")" }}>
            //     <UncontrolledTooltip target={`severity-` + params.row.id} >
            //       {params.row.CVE}
            //     </UncontrolledTooltip>
            //     {SeverityName(params.row.severity)}
            //   </span>

            // )
        },
        {
            Header: 'Due Date',
            name: 'DueDate',
            accessor: 'DueDate',
            defaultFlex: 1,
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            resizable: false,
            Cell: ({ row }) => {
                return new Date(row.original.DueDate).toLocaleDateString('en-US');
            },
            render: ({ value }) => {
                return new Date(value).toLocaleDateString('en-US');
            }
        },
        {
            Header: 'Date Sent',
            name: 'DateSent',
            accessor: 'DateSent',
            defaultFlex: 1,
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            resizable: false,
            Cell: ({ row }) => {
                return new Date(row.original.DateSent).toLocaleDateString('en-US');
            },
            render: ({ value }) => {
                return new Date(value).toLocaleDateString('en-US');
            }
        },
        {
            Header: ' ',
            description: 'This column has a value getter and is not sortable.',
            showColumnMenuTool: false,
            Cell: ({ row }) => {
                if (row.original.Status != "Approved" && row.original.Status != "Sent" && row.original.Status != "Rejected") {
                    return <UncontrolledDropdown>
                        <DropdownToggle className="DefaultButton" caret>
                            Actions
                        </DropdownToggle>
                        <DropdownMenu container="body">
                            <DropdownItem onClick={()=>ApproveAnswer(row.original.Id)}>
                                Approve
                            </DropdownItem>
                            <DropdownItem onClick={() => RejectAnswer(row.original.Id)}>
                                Reject
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                }
                else if (row.original.Status == "Sent") {
                    return <strong>
                        <Button className='DefaultButton' onClick={() => { RefreshAnswer() }}><FontAwesomeIcon icon={faSync} /></Button>
                    </strong>
                }
            }            
        }
    ];
    return (
        <>
            {visibleIssueModal ?
                <Modal ref={issueModalRef} title={`Issues Found for:`} callBack={toggleIssueModal}>
                    <div className="headerText col col-md-12">
                        <hr />
                        <h5> {supplier.Name}</h5>
                        <div role="group" class="btn-group"><Button color="primary" size="sm" onClick={() => navigate(`/portfolio/suppliers/${supplier.Id}/assets`)}>See details</Button></div>
                        <hr />
                    </div>
                    <Row>
                        <Col>
                            <div className="content" style={{maxHeight:"500px",overflow:"auto"} }>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Asset</th>
                                        <th scope="col">Issue</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        readableIssues.map(x => {
                                            const assetName = x.name;
                                            return x.issues.map(z => {
                                                if (z.ScanType.indexOf("DarkWeb") == -1) {
                                                    return <tr>
                                                        <td>{assetName}</td>
                                                        <td>{z.Description}</td>
                                                    </tr>
                                                } else {
                                                    return <tr>
                                                        <td>{assetName}</td>
                                                        <td>{z.ScanType} found {z.Risk_Level}</td>
                                                    </tr>
                                                }
                                            })
                                        })
                                    }
                                </tbody>
                                </table>
                            </div>
                        </Col>
                    </Row>

                </Modal>
                : null
            }
            {
                supplier != null ? <>
                    {
                        supplier.QuestionarySents.length > 0 ?
                            supplier.QuestionarySents.filter(x => x.Status == "Responded").length > 0 ?
                                <UncontrolledAlert color="warning">
                                    You have questionaries pending approval
                                </UncontrolledAlert> : null
                            : null
                    }
                    <Row>
                        <Col md={12}>
                            <div className="d-flex flex-row">
                                <div className="p-2">
                                    <h3 className="dcs-Title">{supplier.Name}</h3>
                                </div>
                                <div className="p-2 align-self-center">
                                    {auth.user.RolePermission.Role.PermissionActions.filter(x => x.ActionP.Name == "Edit" && x.Permission.Name == "Suppliers").length > 0 ?
                                        <>
                                            <UncontrolledTooltip target="supplier-edit">
                                                Edit
                                            </UncontrolledTooltip>
                                            <Link className="white link" to={"/portfolio/edit/" + supplier.Id} id="supplier-edit">
                                                <FontAwesomeIcon size="xl" style={{ paddingLeft: "20px", fontSize: "40px" }} icon={faEllipsis} />
                                            </Link>
                                        </> : null}
                                </div>
                                <div className="p-2 align-self-center">
                                    <FontAwesomeIcon size="xl" style={{ paddingLeft: "20px", fontSize: "20px" }} icon={faEarthAmericas} /> {supplier.Assets.filter(x => x.IsDefault == 1)[0].Name}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <Row>
                                <Col md={12}>
                                    <Card>
                                        <h5 className="dcs-Title">Risk Rating</h5>
                                        {
                                            supplier != null ? <CustomerRatingScore gRating={CalculateRating()} customClass="middleRating" size="xl" /> : <LoaderContainer />
                                        }

                                    </Card>
                                    <Card>
                                        Rating formula:
                                        <span className="ratingFormula">{formula != null ? formula.Formula : ""}</span>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Card>
                                        <h5 className="dcs-Title">Assets</h5>
                                        <h1 style={{ position: "absolute", right: "10px", fontSize: "4rem" }}>{supplier.Assets.filter(x => x.IsOfInterest).length}</h1>
                                    </Card>
                                </Col>
                                <Col md={6}>
                                    <Card>
                                        <h5 className="dcs-Title">Issues</h5>
                                        <h1 style={{ position: "absolute", right: "10px", fontSize: "4rem" }}><span style={{ color: "#FF0000", fontSize: "4rem", cursor: "pointer" }} onClick={readableIssues != null ? toggleIssueModal : null}>{CalculateIssues()}</span>/<span style={{ color: "#1DC39A", fontSize: "4rem" }}>{supplier.Assets.filter(x => x.IsOfInterest).map(x => { return x.AssetScans.length }).reduce((acc, val) => acc + val, 0)}</span></h1>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={2}>
                            <Row>
                                <Col md={12}>
                                    <Row>
                                        <Col md={12}>
                                            <Card>
                                                <h5 className="dcs-Title">Cyber Posture Rating</h5>
                                                <SmallRating rating={CalculateCyberPosture()} size="xl" />
                                            </Card>
                                        </Col>
                                    </Row>

                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Card>
                                        <h5 className="dcs-Title">Questionary Rating</h5>
                                        {supplier != null ? <>
                                            <SmallRating rating={CalculateQuestionaryRating()} size="xl" />
                                        </> : null}
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Card>
                                        <h5 className="dcs-Title">Business Impact</h5>
                                        <Row>
                                            <Col>
                                                <BusinessImpact size="xl" showText={true} impactLevel={supplier.BusinessImpact.businessImpact} />
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>

                            </Row>
                        </Col>
                        <Col md={6}>
                            <Card>
                                {/*<DataTable showTitle={true} tips="Tips: You can review the answers by clicking the name of the Questionary. To approve or reject the answers click on the Actions button on the right of each questionary" rowHeight={50} title={"Questionaries Sent"} data={supplier.QuestionarySents != undefined ? supplier.QuestionarySents : []} PageSize={10} showCellBorders={false} showZebraRows={false} columns={QuestioanryColumns} />*/}
                                {/* <img src="/images/image1.png" />                                
                                <h2 className='text-center'>Request pentesting service</h2> */}
                                <DynamicTable showTitle={true} tips="Tips: You can review the answers by clicking the name of the Questionary. To approve or reject the answers click on the Actions button on the right of each questionary" rowHeight={50} title={"Questionaries Sent"} data={supplier.QuestionarySents != undefined ? supplier.QuestionarySents : []} PageSize={10} showCellBorders={false} showZebraRows={false} columns={QuestioanryColumns} />
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Row>
                                <Col md={4}>
                                    <Card>
                                        <h5 className="dcs-Title">Network</h5>
                                        <UncontrolledAccordion open={-1}>
                                            <AccordionItem>
                                                <AccordionHeader targetId="1">DNS <div className="sectionScore">{CalculateDNS().value}</div><DCSPill className={100 - CalculateDNS().value > 0 ? "issue" : ""} /></AccordionHeader>
                                                <AccordionBody accordionId="1">

                                                    {
                                                        CalculateDNS().key
                                                    } entries with {CalculateDNS().value > 0 ? 100 - CalculateDNS().value : "no"} issues
                                                </AccordionBody>
                                            </AccordionItem>
                                            <AccordionItem>
                                                <AccordionHeader targetId="2">Mail Server <div className="sectionScore">{CalculateEmail().value}</div><DCSPill className={100 - CalculateEmail().value > 0 ? "issue" : ""} /></AccordionHeader>
                                                <AccordionBody accordionId="2">
                                                    {
                                                        CalculateEmail().key
                                                    } entries with {CalculateEmail().value > 0 ? 100 - CalculateEmail().value : "no"} issues
                                                </AccordionBody>
                                            </AccordionItem>
                                            <AccordionItem>
                                                <AccordionHeader targetId="3">NS Records <div className="sectionScore">{CalculateNS().value}</div><DCSPill className={100 - CalculateNS().value > 0 ? "issue" : ""} /></AccordionHeader>
                                                <AccordionBody accordionId="3">
                                                    {
                                                        CalculateNS().key
                                                    } entries with {CalculateNS().value > 0 ? 100 - CalculateNS().value : "no"} issues
                                                </AccordionBody>
                                            </AccordionItem>
                                            <AccordionItem>
                                                <AccordionHeader targetId="4">Vulnerabilities <div className="sectionScore">{CalculateVulnerabilities().value}</div><DCSPill className={100 - CalculateVulnerabilities().value > 0 ? "issue" : ""} /></AccordionHeader>
                                                <AccordionBody accordionId="4">
                                                    {
                                                        CalculateVulnerabilities().key
                                                    } entries with {CalculateVulnerabilities().value > 0 ? 100 - CalculateVulnerabilities().value : "no"} issues
                                                </AccordionBody>
                                            </AccordionItem>
                                        </UncontrolledAccordion>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <h5 className="dcs-Title">Web</h5>
                                        <UncontrolledAccordion open={-1}>
                                            <AccordionItem>
                                                <AccordionHeader targetId="1">SSL Certificates <div className="sectionScore">{CalculateWeb().value}</div><DCSPill className={100 - CalculateWeb().value > 0 ? "issue" : ""} /></AccordionHeader>
                                                <AccordionBody accordionId="1">
                                                    {
                                                        CalculateWeb().key
                                                    } entries with {CalculateWeb().value > 0 ? 100 - CalculateWeb().value : "no"} issues
                                                </AccordionBody>
                                            </AccordionItem>
                                            <AccordionItem>
                                                <AccordionHeader targetId="2">SQL <div className="sectionScore">{CalculateSQL().value}</div><DCSPill className={100 - CalculateSQL().value > 0 ? "issue" : ""} /></AccordionHeader>
                                                <AccordionBody accordionId="2">
                                                    {
                                                        CalculateSQL().key
                                                    } entries with {CalculateSQL().value > 0 ? 100 - CalculateSQL().value : "no"} issues
                                                </AccordionBody>
                                            </AccordionItem>
                                            <AccordionItem>
                                                <AccordionHeader targetId="3">Cross-Site Scripting <div className="sectionScore">{CalculateCSS().value}</div><DCSPill className={100 - CalculateCSS().value > 0 ? "issue" : ""} /></AccordionHeader>
                                                <AccordionBody accordionId="3">
                                                    {
                                                        CalculateCSS().key
                                                    } entries with {CalculateCSS().value > 0 ? 100 - CalculateCSS().value : "no"} issues
                                                </AccordionBody>
                                            </AccordionItem>
                                            <AccordionItem>
                                                <AccordionHeader targetId="4">CORS Misconfiguration <div className="sectionScore">{CalculateCORS().value}</div><DCSPill className={100 - CalculateCORS().value > 0 ? "issue" : ""} /></AccordionHeader>
                                                <AccordionBody accordionId="4">
                                                    {
                                                        CalculateCORS().key
                                                    } entries with {CalculateCORS().value > 0 ? 100 - CalculateCORS().value : "no"} issues
                                                </AccordionBody>
                                            </AccordionItem>
                                        </UncontrolledAccordion>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <h5 className="dcs-Title">DarkWeb</h5>
                                        <UncontrolledAccordion open={-1}>
                                            <AccordionItem>
                                                <AccordionHeader targetId="1">Emails <div className="sectionScore">{CalculateDarkWeb("DarkWeb-Credentials")}</div> <DCSPill className={CalculateDarkWeb("DarkWeb-Credentials") > 0 ? "issue" : ""} /></AccordionHeader>
                                                <AccordionBody accordionId="1">
                                                    {CalculateDarkWeb("DarkWeb-Credentials")} entries
                                                </AccordionBody>
                                            </AccordionItem>
                                            <AccordionItem>
                                                <AccordionHeader targetId="2">Credentials <div className="sectionScore"> {CalculateDarkWeb("DarkWeb-Emails")} </div> <DCSPill className={CalculateDarkWeb("DarkWeb-Emails") > 0 ? "issue" : ""} /></AccordionHeader>
                                                <AccordionBody accordionId="2">
                                                    {CalculateDarkWeb("DarkWeb-Emails")}  entries
                                                </AccordionBody>
                                            </AccordionItem>
                                            <AccordionItem>
                                                <AccordionHeader targetId="3">Sessions <div className="sectionScore"> {CalculateDarkWeb("DarkWeb-Sessions")} </div><DCSPill className={CalculateDarkWeb("DarkWeb-Sessions") > 0 ? "issue" : ""} /></AccordionHeader>
                                                <AccordionBody accordionId="3">
                                                    {CalculateDarkWeb("DarkWeb-Sessions")}  entries
                                                </AccordionBody>
                                            </AccordionItem>

                                        </UncontrolledAccordion>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row >
                    {
                        questionary != null ?
                            <Modal title={questionary.Questionary.Name} ref={ModalRef} callBack={() => { setQuestionary(null) }}>
                                <Row>
                                    <Col>
                                        <ReactFormGenerator read_only={true} data={JSON.parse(questionary.Questionary.Questionary)} hide_actions={true} display_short={false} answer_data={JSON.parse(questionary.Response)} />
                                    </Col>
                                </Row>
                            </Modal>
                            : null
                    }

                </> : <LoaderContainer />
            }
        </>
    )
}

export default SupplierPage