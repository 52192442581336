import React,{useContext} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowTrendUp, faArrowTrendDown, faProjectDiagram } from '@fortawesome/free-solid-svg-icons'
import { Row, Col, Badge } from "reactstrap";
import "./SupplierCounter.css"
import {ThemeContext} from "../../../contexts/themeContext";

const SupplierCounter = (props) => {
    const {theme}= useContext(ThemeContext)
    const { number, percentage } = props
    return (
        <Col md="4" xs="12" className="supplierCounter">
            <Row>
                <Col xs="3">
                    <h3><Badge pill color={theme=="dark"?"light":"secondary"} className="pillCenter"><FontAwesomeIcon icon={faProjectDiagram} size="xl" /></Badge></h3>
                </Col>
                <Col xs="9">
                    <Row>
                        <Col xs="12" className="gray-text">Suppliers</Col>
                        <Col xs="12"><h1>{number != undefined ? number : 0}</h1>
                        </Col>
                        <Col xs="12">
                           <strong className="small-text"><span className="new-suppliers">{number != undefined ? percentage : 0}%</span> new suppliers</strong>
                        </Col>
                    </Row>                    
                </Col>
            </Row>
        </Col>
    )
}
export default SupplierCounter;