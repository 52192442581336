import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup, Col, Collapse, Input, Label, Nav, NavItem, Navbar, Row } from 'reactstrap';
import Card from '../../../components/ui/Card/Card';
import { ThemeContext } from '../../../contexts/themeContext';
import { getUsers } from '../../../data/administration';
import LoaderContainer from '../../../components/ui/LoaderContainer/LoaderContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import DataTable from '../../../components/ui/Table/DataTable';
import { useAuth } from '../../../contexts/authContext';
import UserAddModal from './UserAddModal';

const Users = (props) => {
    const Title = "Users";
    const [Users, setUsers] = useState(null);
    const [user, setUser] = useState(null);
    const [addUser, setAddUser] = useState(false);
    const authContext = useAuth();
    useEffect(() => {
        getUsers(authContext.token, null, authContext.user.ClientId).then(x => {
            if (x.data != undefined) {
                setUsers(x.data);
            }
        })
    }, []);
    const showModal = (show) => {
        setAddUser(!addUser);
    }
    const AddUpdateUserCallback = () => {
       // alert("intra");
        setUsers(null);
        getUsers(authContext.token, null, authContext.user.ClientId).then(x => {
            if (x.data != undefined) {
                setUsers(x.data);
            }
        })
    }
    const columns = [
        {
            header: 'Name',
            name: 'Name',
            sortable: true,
            defaultFlex: 1,
        },
        {
            header: 'Email',
            name: 'Email',
            defaultFlex: 1,
            sortable: true,
            //   render: ({ data }) => {
            //     //console.log(params)
            //     return <strong>
            //       <SmallRating rating={data.rating} />
            //     </strong>
            //   }
        },
        {
            header: 'Role',
            name: 'RolePermission',
            defaultFlex: 1,
            sortable: true,
            render: ({ data }) => {
                //console.log(params)
                return (<>{data.RolePermission.Role.Name}</>)
            }
        },
        {
            header: '',
            name: 'Actions',
            sortable: false,
            render: ({ data }) => {               
                return <Button color="primary" size="sm" onClick={() => { setUser(data); showModal(); }} >Edit</Button>
            }
        },
    ];
    return (
        <>
            {
                addUser ?
                    <UserAddModal setShowModal={setAddUser} user={user} callback={AddUpdateUserCallback} /> : null
            }
            <Row className="white">
                <Col xs md="12" className="headerText">
                    <h3 className="dcs-Title bold">{Title}</h3>
                    <Button className='btn-primary' size="sm" onClick={() => {setUser(null); showModal() }}>Add User</Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card>
                        {Users == null ? <LoaderContainer /> :

                            <DataTable columns={columns} pagination={true} data={Users} />
                        }

                    </Card>
                </Col>
            </Row>

        </>
    )
}
export default Users;