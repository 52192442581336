import React,{useContext} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons';
import { ThemeContext } from '../../../contexts/themeContext';
import "./ThemeToggle.css";
import { Button } from 'reactstrap';
const ThemeToggle = (props) => {
    const { theme, setTheme } = useContext(ThemeContext);   
    const handleThemeChange = () => {
        const isCurrentDark = theme === 'light';
        setTheme(isCurrentDark ? 'dark' : 'light');
      };
    return (<>
      <Button type='button' color='transparent'><FontAwesomeIcon size="xl" className={theme+`-toggleicon`} icon={theme==="light" ? faSun : faMoon} onClick={handleThemeChange} /></Button>
    </>)
}
export default ThemeToggle;