import { useEffect } from "react";
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from "recharts";



const LineChart = (props) => {
    const { data, subtitle,height } = props;
    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <p className="primary" style={{ borderRadius: "5px", color: "#FFF", padding: "5px" }}>{`${payload[0].value}`}<br />
                        {`${payload[0].payload.label}`}</p>
                </div>
            );
        }

        return null;
    };

    return (
        <div className="chart">
            <div className="title">{subtitle}</div>
            <ResponsiveContainer width="100%"  aspect={height==undefined?2 / 1:height}>
                <AreaChart
                    width={730}
                    height={height}
                    data={data}
                    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                >
                    <defs>
                        <linearGradient id="total" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#1D56A3" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#1D56A3" stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <XAxis dataKey="name" stroke="gray" />
                    <YAxis domain={[0, 100]} />
                    <CartesianGrid strokeDasharray="3 3" className="chartGrid" />
                    <Tooltip content={CustomTooltip} />
                    <Area
                        type="monotone"
                        dataKey="value"
                        stroke="#1D56A3"
                        fillOpacity={1}
                        fill="url(#total)"
                    />
                </AreaChart>
            </ResponsiveContainer>
        </div>
    );
};

export default LineChart;