import React, { Component, useState, useContext } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import Search from '../Search/Search';
import Language from '../Language/Language';
import Notification from '../Notification/Notification';
import UserControl from '../UserControl/UserControl';
import { useLocation } from 'react-router-dom';
import ThemeToggle from '../ThemeToggle/ThemeToggle';
import { ThemeContext } from '../../../contexts/themeContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faEllipsisV } from '@fortawesome/free-solid-svg-icons';//{faEllipsisv}
import { useAuth } from '../../../contexts/authContext';

const NavMenu = (props) => {
  const { theme, setTheme } = useContext(ThemeContext);
  const auth = useAuth();
  const location = useLocation();
  const { pathname } = location;
  const [collapsed, setCollapsed] = useState(false);
  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  }
  const [collapsedMain, setCollapsedMain] = useState(false);
  const toggleNavbarMain = () => {
    setCollapsedMain(!collapsedMain);
  }

  // console.log(pathname);
  return (
    <header className="dcs-maineHeader">
      <Navbar className="headertop" full="true" expand="md" container="fluid" color={theme == "dark" ? "dark" : "light"} dark={theme == "dark" ? true : false}>
        <NavbarBrand href="/">
          <img src="/images/dcslogo2.png" className="logo" alt="dcs" />{auth.user != undefined ? auth.user.Conf.Brand : ""}
        </NavbarBrand>
        <NavbarToggler onClick={toggleNavbar}><FontAwesomeIcon icon={faEllipsisV} size="xl" />  </NavbarToggler>
        <Collapse isOpen={collapsed} navbar >
          <Nav className="ml-auto" navbar>
            <NavItem>
              <ThemeToggle />
            </NavItem>
            {/* <NavItem>
              <Language />
            </NavItem> */}
            <NavItem>
              <Notification />
            </NavItem>
            <NavItem>
              <UserControl />
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
      <Navbar className="primary" full="true" expand="md" color={theme == "dark" ? "dark" : "light"} dark={theme == "dark" ? true : false}>
        <NavbarToggler onClick={toggleNavbarMain} className="mr-2"><FontAwesomeIcon className='navbar-toggler-icon' icon={faBars} size="sm" />  </NavbarToggler>
        <Collapse isOpen={collapsedMain} navbar>
          <Nav className="mr-auto" navbar>
            <NavItem>
              <NavLink tag={Link} className={pathname == "/" ? "active" : ""} to="/"  >Dashboard</NavLink>
            </NavItem>
            {auth.user.RolePermission != undefined ?
              <>
                {
                  auth.user.RolePermission.Role.PermissionActions.filter(x => x.ActionP.Name == "View" && x.Permission.Name == "Portfolio").length > 0 ?
                    <NavItem>
                      <NavLink tag={Link} className={pathname == "/portfolio" || pathname.indexOf("/portfolio") != -1 ? "active" : "/"} to="/portfolio">Portolio</NavLink>
                    </NavItem> : null
                }
                {
                  auth.user.RolePermission.Role.PermissionActions.filter(x => x.ActionP.Name == "View" && x.Permission.Name == "Threats").length > 0 ?
                    <NavItem>
                      <NavLink tag={Link} className={pathname == "/threats" ? "active" : ""} to="/threats">Threats</NavLink>
                    </NavItem>
                    : null
                }
                {
                  auth.user.RolePermission.Role.PermissionActions.filter(x => x.ActionP.Name == "View" && x.Permission.Name == "Reporting").length > 0 ?
                    <NavItem>
                      <NavLink tag={Link} className={pathname == "/reports" ? "active" : ""} to="/reports">Reports</NavLink>
                    </NavItem> : null
                }
                {
                  auth.user.RolePermission.Role.Name == "Administrator" || auth.user.RolePermission.Role.PermissionActions.filter(x => x.ActionP.Name == "View" && (x.Permission.Name == "Users" || x.Permission.Name == "Rating" || x.Permission.Name == "Questionaries")).length > 0 ?
                    <NavItem>
                      <NavLink tag={Link} className={pathname == "/admin" ? "active" : ""} to="/admin">Administration</NavLink>
                    </NavItem> : null
                }
              </>
              : null}

          </Nav>
        </Collapse>
      </Navbar>
      {pathname.indexOf("/admin") == -1 ? null :
        <Navbar className="secondary" full="true" expand="md" color={theme == "dark" ? "dark" : "light"} dark={theme == "dark" ? true : false}>
          <NavbarToggler onClick={toggleNavbarMain} className="mr-2"><FontAwesomeIcon className='navbar-toggler-icon' icon={faBars} size="lg" />  </NavbarToggler>
          <Collapse isOpen={collapsedMain} navbar>
            <Nav className="mr-auto" navbar>
              {auth.user.RolePermission.Role.PermissionActions.filter(x => x.ActionP.Name == "View" && x.Permission.Name == "Questionaries").length > 0 ?
                <NavItem>
                  <NavLink tag={Link} className={pathname == "/admin/questionaries" ? "active" : ""} to="/admin/questionaries">Questionaries</NavLink>
                </NavItem> : null}
              {auth.user.RolePermission.Role.PermissionActions.filter(x => x.ActionP.Name == "Edit" && x.Permission.Name == "Users").length > 0 ?
                <NavItem>
                  <NavLink tag={Link} className={pathname == "/admin/Users" ? "active" : ""} to="/admin/Users"  >Users</NavLink>
                </NavItem> : null}
              {auth.user.RolePermission.Role.PermissionActions.filter(x => x.ActionP.Name == "View" && x.Permission.Name == "Rating").length > 0 ?
                <NavItem>
                  <NavLink tag={Link} className={pathname == "/admin/rating" || pathname.indexOf("/admin/rating") != -1 ? "active" : "/"} to="/admin/rating">Rating Formula</NavLink>
                </NavItem> : null}
              {auth.user.RolePermission.Role.Name == "Administrator" ?
                <NavItem>
                  <NavLink tag={Link} className={pathname == "/admin/profiles" ? "active" : ""} to="/admin/profiles">Profiles</NavLink>
                </NavItem> : null}
              {/* <NavItem>
                <NavLink tag={Link} className={pathname == "/admin/notifications" ? "active" : ""} to="/admin/notifications">Notifications</NavLink>
              </NavItem> */}
            </Nav>
          </Collapse>
        </Navbar>
      }
    </header>
  )
}

// export class NavMenu extends Component {
//   static displayName = NavMenu.name;

//   constructor(props) {
//     super(props);

//     this.toggleNavbar = this.toggleNavbar.bind(this);
//     this.state = {
//       collapsed: true
//     };
//   }
//   changeSelected() {

//   }
//   toggleNavbar() {
//     this.setState({
//       collapsed: !this.state.collapsed
//     });
//   }

//   render() {
//     return (
//       <header className="dcs-maineHeader">
//         <Navbar className='navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow' color="light" light>
//           <NavbarBrand>
//             <img src="/assets/dcslogo2.png" alt="dcs" />  <span className='clientName'>Data Core Systems S.R.L.</span>
//           </NavbarBrand>
//           <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
//             <ul className="navbar-nav flex-grow">
//               <NavItem className='form-group'>
//                 <Search />
//               </NavItem>
//               <NavItem>
//                 <Language />
//               </NavItem>
//               <NavItem>
//                 <Notification />
//               </NavItem>
//               <NavItem>
//                 <UserControl />
//               </NavItem>
//             </ul>
//           </Collapse>
//         </Navbar>
//         <Navbar className="primary" dark expand>
//           <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
//           <Collapse isOpen={!this.state.collapsed} navbar>
//             <ul className="navbar-nav flex-grow">
//               <NavItem>
//                 <NavLink tag={Link} {location.pathname == Link ? active : null} to="/"  >Dashboard</NavLink>
//               </NavItem>
//               <NavItem>
//                 <NavLink tag={Link} to="/portfolio">Portolio</NavLink>
//               </NavItem>
//               <NavItem>
//                 <NavLink tag={Link} to="/threats">Threats</NavLink>
//               </NavItem>
//               <NavItem>
//                 <NavLink tag={Link} to="/reports">Reports</NavLink>
//               </NavItem>
//               <NavItem>
//                 <NavLink tag={Link} to="/administration">Administration</NavLink>
//               </NavItem>
//             </ul>
//           </Collapse>
//         </Navbar>
//       </header>
//     );
//   }
// }
export default NavMenu;
