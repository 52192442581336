import { useEffect, useState } from 'react';
import { HeatMapGrid } from 'react-grid-heatmap';
import { PostureRatingSupplier } from '../../../utils/ratingCalculator';

const HeatMap = (props) => {
    const { data } = props;
    const xLabels = ["Minimal", "Minor", "Moderate", "Significant", "Severe"]
    const yLabels = ["Critical", "Bad", "Good", "Great"];
    const [heatMapData, setHeatMapData] = useState([]);
    const dataT = new Array(yLabels.length)
        .fill(0)
        .map(() =>
            new Array(xLabels.length).fill(0).map(() => 0)
        )
    useEffect(() => {
        CalculateHeatMap();
    }, [data])
    const CalculateHeatMap = () => {
        data.map(x => {
            // let positionRating = 0;
            // let a = x.Score;
            // console.log(a);
            // // let a = x.Assets.map(z => {
            // //     if (z.AssetScans.length > 0) {
            // //         return z.AssetScans.reduce((acc, val) => acc + (!val.Ignore ? val.Risk_Level >= 2 ? val.Risk_Level : 0 : 0), 0);
            // //     } else { return 0 }
            // // });
            // // a = a.filter(l => l !== undefined);
            // //Posture Calculation
            // const posture = a.reduce((acc, val) => acc + val, 0) / a.length;
           // const PostureRating = posture / 10 * 100
            const PostureRating=x.PostureRating;
            if (PostureRating >= 0 && PostureRating <= 25)
                dataT[0][x.Supplier.BusinessImpact.businessImpact] += 1;
            //return [0, 0, 0, 0, 1];
            if (PostureRating > 25 && PostureRating <= 50)
                dataT[1][x.Supplier.BusinessImpact.businessImpact] += 1;
            //return [0, 0, 0, 1, 0];
            if (PostureRating > 50 && PostureRating <= 75)
                dataT[2][x.Supplier.BusinessImpact.businessImpact] += 1;
            //return [0, 0, 1, 0, 0];
            if (PostureRating > 75 && PostureRating <= 100)
                dataT[3][x.Supplier.BusinessImpact.businessImpact] += 1;
            //console.log(dataT);
            setHeatMapData(dataT);
        });
        //console.log(dataT);
        return dataT;
    }
    return (
        heatMapData.length > 0 ?
            <HeatMapGrid
                data={heatMapData}
                xLabels={xLabels}
                yLabels={yLabels}
                xLabelsPos="bottom"
                square={false}
                cellHeight={"2rem"}
                cellStyle={(_x, _y, ratio) => {
                    //console.log(_x + " " + _y + " " + ratio)
                    if (ratio > 0 && ((_x == 3 && _y == 0) || (_x == 3 && _y == 1) || (_x == 3 && _y == 2) || (_x == 2 && _y == 0) || (_x == 2 && _y == 1) || (_x == 2 && _y == 2) || (_x == 3 && _y == 3) || (_x == 3 && _y == 4) || (_x == 1 && _y == 0))) {
                        return ({
                            background: `rgb(27,138,90)`,
                            fontSize: ".7rem",

                            margin: "0px",
                            borderRadius: "0px",
                            border: "0px",
                            color: `rgb(255, 255, 255, 1)`
                        })
                    }
                    if (ratio > 0 && ((_x == 1 && _y == 0) || (_x == 1 && _y == 1) || (_x == 1 && _y == 2) || (_x == 0 && _y == 0) || (_x == 0 && _y == 1) || (_x == 3 && _y == 3) || (_x == 2 && _y == 3))) {
                        return ({
                            background: `rgb(251,176,33)`,
                            fontSize: ".7rem",

                            margin: "2px",
                            borderRadius: "0px",
                            border: "0px",
                            color: `rgb(0, 0, 0)`
                        })
                    }
                    if (ratio > 0) {
                        return ({
                            background: `rgb(238,62,50)`,
                            fontSize: ".7rem",

                            margin: "2px",
                            borderRadius: "0px",
                            border: "0px",
                            color: `rgb(255, 255, 255)`
                        })
                    }
                    return ({
                        background: `rgba(50,50,50,0.2)`,
                        fontSize: ".7rem",

                        margin: "2px",
                        borderRadius: "0px",
                        border: "0px",
                        color: `rgba(50, 50, 50,0.2)`
                    })
                }}
                cellRender={(x, y, value) => (
                    <div>{value}</div>
                )}
            /> : null
    )
}
export default HeatMap