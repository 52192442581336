import React, { useState } from "react";
import "./login.css";
import { useAuth } from "../contexts/authContext";
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindows } from '@fortawesome/free-brands-svg-icons';
import axios from "axios";
import { Alert, Button } from "reactstrap";
import LoaderContainer from "../components/ui/LoaderContainer/LoaderContainer";
export default function Login() {
  const value = useAuth();
  const location = useLocation();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
 
  function doLogin(event) {
    setLoading(true);
    event.preventDefault();
    value.onLogin(username, password).then(rez => {
      if (rez.login == "failed") {
        console.log(rez.error);
        setError(rez.error);
        setLoading(false);
      }
    });
  }
  const url = "/auth/InitiateSingleSignOn?returnUrl=" + queryParams.get('returnUrl');
  //console.log(url);
  return (

    <div className="loginGB">
      {error != null ?
        <Alert color="danger">{error}</Alert>
        : null}
      <div className="login-wrapper">


        <div className="login-form">

          <img src="/images/dcslogo.png" alt="Dcs Logo" />
          {
            !loading ? <>
              <h2>Login</h2>
              <form className="form mh-400" method="post" onSubmit={doLogin}>
                <div className="form-group">
                  <label htmlFor="username">Username</label>
                  <input type="text" name="username" placeholder="username" value={username} onChange={(e) => setUsername(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="username">Password</label>
                  <input type="password" name="pdw" placeholder="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                </div>
                <div className="form-group">
                  <button className="button" type="submit">Submit</button>
                </div>
              </form>
              {process.env.REACT_APP_SECRET_NAME?
              <form className="form mh-400" method="get" action={url} >
                <input type="hidden" value={queryParams.get('returnUrl')} name="returnUrl" />
                <div className="form-group text-center">
                  <Button className="btn button" type="submit" color={"primary"}><FontAwesomeIcon icon={faWindows} size="2x" /></Button>
                </div>
              </form>:null}
            </>
              : <LoaderContainer />}
        </div>

      </div>
    </div>
  )
}
