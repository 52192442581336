import React, { useContext, Component, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import NavMenu from '../components/ui/NavMenu/NavMenu';
import { matchPath, useLocation } from 'react-router-dom';
import AppRoutes from '../routes/AppRoutes';
import Breadcrumbs from '../components/ui/Breadcrumbs/Breadcrumbs';
import { ThemeContext } from '../contexts/themeContext';
const Layout = (props) => {
  const { theme, setTheme } = useContext(ThemeContext);
  const location = useLocation();

  // const filterRoutes = (routes, path) => {
  //   const segments = path.split('/').filter(Boolean);
  //   let potentialRoutes = [...routes];
  //   let filteredRoutes = [];

  //   segments.forEach((segment, index) => {
  //     const matchingRoutes = potentialRoutes.filter(route => {
  //       const routeSegments = route.path.split('/').filter(Boolean);
  //       const routeSegment = routeSegments[index];

  //       return routeSegment === segment || routeSegment?.startsWith(':');
  //     });

  //     if (matchingRoutes.length > 0) {
  //       potentialRoutes = matchingRoutes; // Update the potential routes to further narrow down
  //       filteredRoutes.push(...matchingRoutes);
  //     }
  //   });

  //   // Add base path '/'
  //   // filteredRoutes.push(routes.find(route => route.path === '/'));

  //   let p = filteredRoutes.sort((a, b) => {
  //     const staticSegmentsA = a.path.split('/').filter(seg => !seg.startsWith(':')).length;
  //     const staticSegmentsB = b.path.split('/').filter(seg => !seg.startsWith(':')).length;
  //     return staticSegmentsB - staticSegmentsA;
  //   });
  //   p = p.filter((item, index, self) =>
  //     item.path.indexOf(":") == -1 ||
  //     self.findIndex(i => i.path.indexOf(":") != -1) === index
  //   );
  //   console.log(p);
  //   p.push(routes.find(route => route.path === '/'));
  //   // Deduplicate and filter
  //   return [...new Set(p.reverse())];
  // };

  return (
    <div className={theme == "dark" ? "theme-dark" : "theme-light"}>
      <div className='generalbg'></div>
      <NavMenu />
      <Container fluid className='white'>
        {/* <Breadcrumbs crumbs={filterRoutes(AppRoutes, location.pathname)} /> */}
        {props.children}
      </Container>
    </div>
  );
}
export default Layout;