
import axios from "axios";

export const getRoles=(_id,_clientid)=>{
    const _url="/administration/roles";
    const params={
        id:_id,
        ClientId:_clientid
    }
    return axios.get(_url,{params});
    
    
}
export const addRole=(Role)=>{
    const _url="/administration/addrole";
    return axios.post(_url,{Role});
}
export const getPermissions=(_id)=>{
    const _url="/administration/permissions";
    if(_id!=undefined)
    {
        return axios.get(_url,{_id});
    }
    return axios.get(_url);
}

export const getActions=(_id)=>{
    const _url="/administration/actions";
    if(_id!=undefined)
    {
        return axios.get(_url,{_id});
    }
    return axios.get(_url);
}
export const getPermissionsActions=(_id)=>{
    const _url="/administration/permissionactions";
    //console.log(_id);
    if(_id!=undefined)
    {
        return axios.get(_url,{params:{id:_id}});
    }
    return axios.get(_url);
}

export const SetPermissionsActions=(permissionaction)=>{
    const _url="/administration/savepermissionaction";
  ;
    if(permissionaction!=undefined)
    {
        return axios.post(_url,permissionaction);
    }   
}

export const getUsers=(token,Id,ClientId)=>{
   
    const _url="/administration/users";
    const headers={
        "Authorization":"Bearer "+token
    }
    const params={
        Id:Id,
        ClientId:ClientId
    }
    
    return axios.get(_url,{params},{headers:headers});
}
export const UpdatePassword=(user)=> {  
    const _url = "/auth/updatePassword";
    return axios.post(_url,  user );
}
export const saveUser=(user)=>{
    const _url="/administration/adduser";
    // const headers={
    //     "Authorization":"Bearer "+token
    // }    
    return axios.post(_url,user);
}

export const updateUser=(user)=>{
    const _url="/administration/updateuser";
    // const headers={
    //     "Authorization":"Bearer "+token
    // }    
    return axios.patch(_url,user);
}
export const getRatings=(token,ClientId)=>{
   
    const _url="/administration/getrating";
    const headers={
        "Authorization":"Bearer "+token
    }  
    const params={  
        Id:null,
        DateCreated:null,     
        ClientId:ClientId
    }
    return axios.get(_url,{params});
}

export const updateRating=(token,rating)=>{
    const _url="/administration/postrating";
    // const headers={
    //     "Authorization":"Bearer "+token
    // }    
    return axios.post(_url,rating);
}