import React from 'react';
import "./BusinessImpact.css";

const BusinessImpact = (props) => {
    const { impactLevel, size,showText } = props;
    const impactText=[
        "Minimal","Minor","Moderate","Significant","Critical"
    ];
    return (
        <div className={`${size != undefined ? size : null}`}>
            <div className="businessImpact">
                <div className={`dcs-blvl1 ${impactLevel >= 0 ? "active" : null}`}></div>
                <div className={`dcs-blvl2 ${impactLevel >= 1 ? "active" : null}`}></div>
                <div className={`dcs-blvl3 ${impactLevel >= 2 ? "active" : null}`}></div>
                <div className={`dcs-blvl4 ${impactLevel >= 3 ? "active" : null}`}></div>
                <div className={`dcs-blvl5 ${impactLevel >= 4 ? "active" : null}`}></div>
            </div>
            {showText?
            <div className="impactText">{impactText[impactLevel]}</div>:null
            }
        </div>
    )

}
export default BusinessImpact;