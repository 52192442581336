import React from 'react';
import "./pill.css"
const DCSPill = (props) => {

    const { className } = props;

    return (
        <>
            <div className={`dcspill ${className}`}></div>
        </>
    )
}
export default DCSPill;