import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useCallback, useEffect } from 'react';
import { PieChart, Pie, Sector, Cell, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { faArrowTrendUp } from '@fortawesome/free-solid-svg-icons'
import { Col, Row } from 'reactstrap';
import RatingCalculator, { PostureRatingSupplier } from '../../../../utils/ratingCalculator';
import QuestionaryCalculator from '../../../../utils/questionaryCalculator';
import LoaderContainer from '../../LoaderContainer/LoaderContainer';
import RatingFormulaCalculator from '../../../../utils/ratingFormulaCalculator';

const DCSDoughnut = (props) => {
    const { supplierData } = props;
    const [activeIndex, setActiveIndex] = useState(null);
    const [d, setD] = useState([]);
    const onMouseOver = useCallback((data, index) => {
        setActiveIndex(index);
    }, []);
    const onMouseLeave = useCallback((data, index) => {
        setActiveIndex(null);
    }, []);
    useEffect(() => {
        if (supplierData != null) {
            setD(supplierData.map(supplier => {
                //console.log(supplier);
                return supplier.QuestionaryRating!=-1?supplier.Rating.Rating:0;
            }));
        }
    }, [])
    // console.log(d);
    const data = [
        { name: "N/A", value: d.filter(x => x == 0).length, count: d.filter(x => x == 0).length + " suppliers" },
        { name: "Great", value: d.filter(x => x >0 && x<=1).length, count: d.filter(x => x >0 && x<=1).length + " suppliers" },
        { name: "Good", value: d.filter(x => x > 1 && x <= 2).length, count: d.filter(x => x > 1 && x <= 2).length + " suppliers" },
        { name: "Bad", value: d.filter(x => x > 2 && x <= 3).length, count: d.filter(x => x > 2 && x <= 3).length + " suppliers" },
        { name: "Critical", value: d.filter(x => x > 3 && x <= 4).length, count: d.filter(x => x > 3 && x <= 4).length + " suppliers" },       
    ]

    const COLORS = ['#a3aed0','rgb(29, 195, 154)', '#02830F', '#F97316', '#EF4444'];
    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.2;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        if (percent > 0) {
            return (
                <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                    {`${(percent * 100).toFixed(0)}%`}
                </text>
            );
        }
    };
    // const renderLegend = (props) => {
    //     const { payload } = props;
    //     // 
    //     return (
    //       <ul>
    //         {
    //           payload.map((entry, index) => (
    //             <li key={`item-${index}`} className="row"><Col md={4}><span className="dcs-legendcircle" style={{backgroundColor:entry.color,whiteSpace:"nowrap"}}></span>{entry.value}</Col><Col  md={4}><span style={{color:entry.color, whiteSpace: "nowrap"}}>{entry.payload.value}%<FontAwesomeIcon icon={faArrowTrendUp} /></span></Col><Col  md={4} ><div style={{whiteSpace: "nowrap"}}>{entry.payload.count}</div></Col></li>
    //           ))
    //         }
    //       </ul>
    //     );
    //   }

    return (
        <ResponsiveContainer height={440}>
            <PieChart>
                <Pie
                    data={data}
                    activeIndex={activeIndex}
                    labelLine={false}
                    label={renderCustomizedLabel}
                    innerRadius={100}
                    borderWidth={0}
                    outerRadius={150}
                    fill="#8884d8"
                    dataKey="value"
                    startAngle={0}
                    endAngle={360}
                    cornerRadius={1}
                    paddingAngle={0}
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} style={{ outline: 'none' }} stroke={0} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
                <Tooltip wrapperStyle={{ backgroundColor: '#000', fontSize: 14 }} />
                <Legend verticalAlign="bottom" align="center" iconType="circle" />
            </PieChart>
        </ResponsiveContainer>
    )
}
export default DCSDoughnut;