import React, { useContext } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowTrendUp, faPercentage } from '@fortawesome/free-solid-svg-icons'
import { Row, Col, Badge } from "reactstrap";
import {ThemeContext} from "../../../contexts/themeContext";
import "./CustomerRatingNScore.css"

const CustomerRatingNScore = (props) => {
    const {theme}= useContext(ThemeContext);
    const { rating, percentage } = props;
    return (
        <Col md="4" xs="12" className="supplierCounter">
            <Row>
                <Col xs="3">
                    <h3><Badge pill color={theme=="dark"?"light":"secondary"} className="pillCenter"><FontAwesomeIcon icon={faPercentage} size="xl" /></Badge></h3>
                </Col>
                <Col xs="9">
                    <Row>
                        <Col xs="12" className="gray-text">Avg. Rating Score</Col>
                        <Col xs="12">
                            <h2 className="supplierCounterNumber">{rating != undefined ? rating : 0}</h2>
                        </Col>
                        {/* <Col xs="12">
                    <span className="new-suppliers">{percentage != undefined ? percentage : 0}%</span>
                    </Col> */}
                    </Row>
                </Col>
            </Row>
        </Col>
    )
}
export default CustomerRatingNScore;