import React, { useRef, useState, useEffect } from 'react';
import Modal from '../Modal/Modal';
import { Col, Row, Form, Label, Button, FormGroup, Input } from 'reactstrap';
import LoaderContainer from '../LoaderContainer/LoaderContainer';
import { useAuth } from '../../../contexts/authContext';
import DcsToggle from '../Designer/Toggle/DcsToggle';
import Select from 'react-select';

import BusinessImpactBig from '../BusinessImpactBig/BusinessImpactBig';
import Countries from '../../../data/countries';
import { AddAsset } from '../../../data/Assets';
const AssetModal = (props) => {
    const { id, SupplierId, setShowModal } = props;
    const CurrentDate = new Date();

    const ClientId = '';
    const [show, setShow] = useState(false);
    const [isLoding, setIsLoading] = useState(null);
    const [countries, setCountries] = useState(null);
    const [sendEnabled, setSendEnabled] = useState(false);
    const [value, setValue] = useState(CurrentDate);
    const [businessImpact,setBusinessImpact]=useState(0);
    const addAssetRef = useRef(null);
    const countryRef = useRef(null);
    const addModalRef = useRef(null);
    const dueDateRef = useRef(null);
    const auth = useAuth();
    const IsOfInterestRef=useRef(null);
    const handleChange = (selectedDate) => {
        console.log(selectedDate);
        setShow(false);
    }
    const handleClose = (state) => {
        setShow(true);
    }


    useEffect(() => {

        setIsLoading(true);
        try {
            Countries().then(rez => {
                if (rez.data != undefined) {
                    const r= rez.data.map(x=>{
                        return { value: x.Id, label: x.Name }
                    })
                    setCountries(r);
                }
            })
            setIsLoading(false);
        } catch (ex) {
            setIsLoading(false);
        }
    }, [])
    const SaveAssetToDb = (e) => {
        
        var fo= e.target;
        e.preventDefault();
        let _asset={
            Name:fo.Name.value,
            Ip:fo.Ip.value,
            CountryId:countryRef.current.getValue()[0].value,
            SupplierId:SupplierId,   
            Network:fo.Network.value,        
            BusinessImpact:businessImpact,
            AssetRef:null,
            IsDefault:false,
            Added:"Manual",
            WebPlatform:fo.WebPlatform.value,
            IsOfInterest:IsOfInterestRef.current.value=="false"?false:true     
        }
        console.log(_asset);
        AddAsset(_asset).then(rez=>{
            console.log(rez);
        })
        // formRef.current.getValue().map(x => {
        //     contactRef.current.getValue().map(y => {
        //         SendQuestionary(x.value, y.value, SupplierId, value).then(rez => {
        //             //TODO add warning if didn't work
        //         })
        //     })
        // })
        setShowModal(false);
    }

    //Dropdown Styles
    const dropdownStile = {
        control: (styles) => ({ ...styles, backgroundColor: '#FFF' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isDisabled ? "#1861ac" : isSelected ? "#1861ac" : isFocused ? "#1861ac" : undefined,
                color: isDisabled ? undefined : isSelected ? "#FFF" : isFocused ? "#FFFFFF" : "#1861ac",
            }
        },
        placeholder: (styles) => ({ ...styles, color: "#999" }),
        singleValue: (styles, { data }) => ({ ...styles, backgroundColor: "#FFF", color: "#1D56A3" })
    }
    const UpdateBusinessImpact=(businessImpact)=>{
        setBusinessImpact(businessImpact);
    }

    return (
        <Modal title="Add Asset" ref={addModalRef} callBack={setShowModal}>
            <Col>
                <form className='row' ref={addAssetRef} onSubmit={SaveAssetToDb}>
                    <FormGroup>
                        <Label>
                            Asset Name
                        </Label>
                        <Input type="text" name="Name" required={true} placeholder="Asset Name" />
                    </FormGroup>
                    <FormGroup>
                        <Label>
                            Ip Address
                        </Label>
                        <Input type="text" name="Ip" required={true} placeholder="0.0.0.0" />
                    </FormGroup>
                    <FormGroup>
                        <Label>
                            Is of Interest?
                        </Label>
                        <DcsToggle ref={IsOfInterestRef} data={{ lable: "" }} />
                    </FormGroup>
                    <FormGroup>
                        <Label>
                            Network
                        </Label>
                        <Input type="text" name="Network" placeholder="Some network" />
                    </FormGroup>
                    <FormGroup>
                        <Label>
                            Country
                        </Label>
                        <Select
                            ref={countryRef}
                            className='col-md-12'
                            styles={dropdownStile}
                            options={countries}
                            isMulti={false}
                            required={true}
                            isClearable
                            isSearchable
                            placeholder="Select Country"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>
                            Web Platform
                        </Label>
                        <Input type="text" name="WebPlatform" placeholder="Wordpress" />
                    </FormGroup>
                    <FormGroup>
                        <Label>
                            Bussines Impact
                        </Label>
                        <BusinessImpactBig impactLevel={businessImpact} callBack={UpdateBusinessImpact}  />
                    </FormGroup>
                    <Button type="submit" color="primary" disabled={sendEnabled}>Add</Button>
                </form>
            </Col>
        </Modal>
    )
}
export default AssetModal;