import React, { useState, useMemo, useEffect } from 'react'
import { ReactFormBuilder, Registry,ElementStore } from 'react-form-builder2';
import 'react-form-builder2/dist/app.css';
import { Col, Row } from 'reactstrap';
import FormElementsEdit from '../../../components/ui/Designer/form-elements-edit';
import FormBuilderPreview from '../../../components/ui/Designer/form-builder-preview';
import DcsToggle from '../../../components/ui/Designer/Toggle/DcsToggle';

const BusinesImpactAddEdit = () => {
    const [formData, setFormData] = useState([]);
    useEffect(()=>{
        Registry.register('DCSToggle', DcsToggle);
    },[])
    ElementStore.subscribe(state => {
        setFormData(state.data)
        console.log(state.data);
    })
    const onPost = () => {
        return;
    }
    const onPostHandler = () => { 
        console.log(formData);
      };

     const items=[{
        key: 'DCSToggle',
        element: 'CustomElement',
        component: DcsToggle,
        type: 'custom',
        field_name: 'toggle_component',
        name: 'Toggle',
        icon: 'fa fa-check',
        static: false,  
        bare:true,      
        label: 'Label Test',
        defaultChecked:false,
      },{  
        key: 'Header',
      }, {
        key: 'TextInput',
      }, {
        key: 'RadioButtons',
      }, {
        key: 'Checkboxes',
      },{
        key: 'Label',
      }
    ] 
    return (
        <>
            <Row>
                <Col>
                    <h4>Business Impact Builder</h4>
                </Col>
            </Row>
            <Row>
                <Col className="black">
                    {/* <ReactFormBuilder  /> */}
                    <FormBuilderPreview data={formData}  />                   
                    <ReactFormBuilder toolbarItems={items} onPost={onPost}  renderEditForm={props => <FormElementsEdit {...props} />} />
                </Col>
            </Row>
        </>
    )

}
export default BusinesImpactAddEdit;