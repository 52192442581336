import React, { useState, forwardRef, useRef, useEffect, useCallback } from 'react';
import "./Toogle.css";
import { Col, Label, Row } from 'reactstrap';
const DcsToggle = forwardRef((props, ref) => {
    const { name, defaultChecked, disabled, defaultValue } = props;    
   
    const [isToggled, toggle] = useState(null);
    const localRef = useRef();    
    const callback = useCallback(() => {
        toggle(!isToggled);        
    }, []);
    useEffect(() => {
        console.log(defaultValue);
        if (defaultValue !== undefined) {
            toggle(defaultValue);
        } else {
            toggle(defaultChecked);
        }
        
    }, [defaultChecked, defaultValue])

    return (<>
        <div className='form-group'>
            <Label>
                <div dangerouslySetInnerHTML={{ __html: props.data.label }} />
            </Label>
            <Label className="toggle ">
                <input type="checkbox" ref={ref == undefined ? localRef : ref} name={name} disabled={disabled} defaultChecked={isToggled} onClick={callback} />
                <span />
            </Label>
        </div>
    </>
    )
});
export default DcsToggle;