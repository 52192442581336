import React, { memo, useState } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup
} from "react-simple-maps";
import { UncontrolledTooltip } from "reactstrap";

const WorldMap = ({ setTooltipContent, setToolTipTarget, countries = [] }) => {
  const [position, setPosition] = useState({ coordinates: [0, 0], zoom: 1 });
  //console.log(countries);
  function handleZoomIn() {
    if (position.zoom >= 4) return;
    setPosition((pos) => ({ ...pos, zoom: pos.zoom * 2 }));
  }

  function handleZoomOut() {
    if (position.zoom <= 1) return;
    setPosition((pos) => ({ ...pos, zoom: pos.zoom / 2 }));
  }

  function handleMoveEnd(position) {
    setPosition(position);
  }
  return (
    <div data-tip="">
      <ComposableMap>
        <Geographies geography="countryMap.json" >
          {({ geographies }) =>
            geographies.map((geo) => {
              // console.log(geo.id);
              const st = countries.filter(x => geo.id == x).length > 0 ? {
                default: {
                  fill: "#F53",
                  outline: "#1D56A3",
                  stroke:"rgba(255,255,255,0.3)"
                },
                hover: {
                  fill: "#F53",
                  outline: "#FFF",
                  stroke:"rgba(255,255,255,0.5)"
                },
                pressed: {
                  fill: "#E42",
                  outline: "#FFF"
                }
              } : {
                default: {
                  fill: "rgba(255,255,255,0.4)",
                  outline: "#1D56A3",
                  stroke:"#1D56A3"
                },
                hover: {
                  fill: "#1D56A3",
                  outline: "#FFF",
                  stroke:"#1D56A3"
                },
                pressed: {
                  fill: "rgba(255,255,255,0.4)",
                  outline: "#FFF",
                  stroke:"rgba(255,255,255,0.3)"
                }
              };
              return (
                <Geography
                  key={geo.rsmKey}
                  id={geo.rsmKey}
                  geography={geo}
                  onMouseEnter={() => {
                    if (countries.filter(x => x == geo.id).length > 0) {
                      setToolTipTarget(geo.rsmKey);   
                                      
                      setTooltipContent(countries.filter(x => x == geo.id).length + " Assets");
                    }
                  }
                  }
                  onMouseLeave={() => {
                    setToolTipTarget(undefined);
                    setTooltipContent("");
                  }}
                  style={st}
                >
                  {countries.filter(x => x == geo.id).length>0?
                  <UncontrolledTooltip target={geo.rsmKey}>
                    <span>{geo.properties.name}</span><br/>
                    <span>{countries.filter(x => x == geo.id).length + " Assets"}</span>
                    </UncontrolledTooltip>:null}
                  </Geography>
              )
            })
          }
        </Geographies>
      </ComposableMap>
    </div>
  );
};

export default memo(WorldMap);
