import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup, Col, Collapse, Input, Label, Nav, NavItem, Navbar, Row } from 'reactstrap';
import Card from '../../../components/ui/Card/Card';
import { ThemeContext } from '../../../contexts/themeContext';
import { SetPermissionsActions, getActions, getPermissions, getPermissionsActions, getRoles } from '../../../data/administration';
import LoaderContainer from '../../../components/ui/LoaderContainer/LoaderContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../../contexts/authContext';

const defaultActions =
{
    view: false,
    edit: false,
    update: false,
    delete: false
}

const Profiles = (props) => {
    const Title = "Profiles";
    const { theme, setTheme } = useContext(ThemeContext);
    const [_roles, setRoles] = useState(null);
    const [_permissions, setPermissions] = useState([]);
    const [_actions, setActions] = useState({});
    const [selectedRole, setSelectedRole] = useState(null);
    const [selectedPermission, setSelectedPermission] = useState(null);
    const [selectedActions, setSelectedActions] = useState([]);
    const [diabledAdd,setDisabledAdd]=useState(true);
    const auth=useAuth();
    useMemo(() => {
        getRoles(null,auth.user.ClientId).then(x => {
            if (x.data != undefined) {
                setRoles(x.data);
            }
        });
        getPermissions().then(x => {
            if (x.data != undefined) {
                setPermissions(x.data);
            }
        });
        getActions().then(x => {
            if (x.data != undefined) {
                setActions(x.data);
            }
        })
        // setActions(defaultActions);
    }, [])
    const handleRButtonClick = (roleName) => {
        setSelectedRole(roleName);
        setSelectedPermission(null);
        setSelectedActions([]);
    };
    const handlePButtonClick = (roleName) => {
        setSelectedPermission(roleName);
    };
    useMemo(() => {
        if (selectedPermission != null) {
            getPermissionsActions(selectedPermission).then(x => {
                if (x.data != undefined) {
                    setSelectedActions(x.data);
                }
            })
        }
    }, [selectedPermission]);

    const handleActionClick = (_id,_pid) => {
        if (_id != undefined) {
            console.log(_id);
            const p = {
                Id:_pid==undefined?null:_pid,
                PermissionId: selectedPermission,
                RoleId: selectedRole,
                ActionId: _id,
                CreatedBy: auth.user.Id
            }
            SetPermissionsActions(p).then(r => {
                getPermissionsActions(selectedPermission).then(x => {
                    if (x.data != undefined) {
                        setSelectedActions(x.data);
                    }
                })
            })
        } else{

        }
    }
    return (
        <>
            <Row className="white">
                <Col className="headerText" md={1}>
                    <h3 className="dcs-Title bold">{Title}</h3>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card>
                        <Row>
                            <Col md={11}>
                                <h5>Roles</h5>
                            </Col>
                            <Col className="d-flex flex-row-reverse">
                                {diabledAdd?<FontAwesomeIcon size="xl" icon={faPlusCircle} color={"gray"} />:
                                <Link id="supplier-edit" >
                                    <FontAwesomeIcon size="xl" icon={faPlusCircle}/>
                                </Link>}
                            </Col>
                        </Row>
                        {
                            _roles!=null ?

                                <ButtonGroup vertical>
                                    {
                                        _roles.map(x => {
                                            return <Button className="DefaultButton" onClick={() => handleRButtonClick(x.Id)} // Set this role as selected on click
                                                active={selectedRole === x.Id} >{x.Name}</Button>
                                        })
                                    }
                                </ButtonGroup> : <LoaderContainer />}
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <h5>Area</h5>
                        {
                            _permissions.length > 0 ?
                                <ButtonGroup vertical>
                                    {
                                        _permissions.map(x => {
                                            return <Button key={x.Id} className="DefaultButton" onClick={() => handlePButtonClick(x.Id)}  // Set this role as selected on click
                                                active={selectedPermission === x.Id}>{x.Name}</Button>
                                        })
                                    }
                                </ButtonGroup> : <LoaderContainer />}
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <h5>Actions</h5>
                        <Row>
                            {
                                _actions.length > 0 && selectedPermission != null ?
                                    _actions.map(x => {
                                        return <>

                                            <Col><Label check for={x.Id} >{x.Name}</Label><Input key={x.Id} type="checkbox" id={x.Id} bsSize="md" onChange={()=>{}} checked={selectedActions.length > 0 ? selectedActions.filter(z => z.ActionId == x.Id && z.RoleId == selectedRole && z.PermissionId == selectedPermission).length > 0 : false} onClick={() => { handleActionClick(x.Id,selectedActions.filter(z => z.ActionId == x.Id && z.RoleId == selectedRole && z.PermissionId == selectedPermission)[0]?.Id) }}></Input></Col>

                                        </>
                                    }) : null
                            }
                        </Row>
                    </Card>
                </Col>
            </Row>

        </>
    )
}
export default Profiles;