import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Col, Collapse, Nav, NavItem, Navbar, Row } from 'reactstrap';
import Card from '../../components/ui/Card/Card';
import { ThemeContext } from '../../contexts/themeContext';
const Administration = (props) => {
    const Title = "Administration";
    const { theme, setTheme } = useContext(ThemeContext);
    return (
        <>           
            <Row className="white">
                <Col xs md="12" className="headerText">
                    <h3 className="dcs-Title bold">{Title}</h3>
                </Col>
            </Row>


        </>
    )
}
export default Administration;