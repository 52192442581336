import React from 'react';
import "./CustomerRatingScore.css"
import { Col, Row } from 'reactstrap';

const CustomerRatingScore = (props) => {
    const { gRating, customClass,size } = props;
    const Scores = [
        { score: "A", rating: "GREAT", color: "#1DC39A" },
        { score: "B", rating: "GOOD", color: "#02830F" },
        { score: "C", rating: "BAD", color: "#F97316" },
        { score: "D", rating: "CRITICAL", color: "#EF4444" },
        { score: "N/A", rating: "N/A", color: "#979797" },
    ];
   // console.log(gRating);
    const defaultRating = Scores.filter(x => x.score == (gRating ? gRating : "N/A"));
    let h1style = { color: defaultRating[0].color, fontSize: "4rem", lineHeight: "0", margin: "0", fontWeight: "700", whiteSpace: "nowrap" };
    let textColor = { color: defaultRating[0].color, marginBottom: "16px" };
    if(size==undefined)
    {
    return (       
       
        <Row>
            <Col md="12" xs="12">
                <Row className={`crating ${customClass != undefined ? customClass : ""}`}>                    
                    <Col xs="3"><h1 style={h1style}>{defaultRating[0].score != "N/A" ? defaultRating[0].score : ""}</h1></Col>
                    <Col xs="7">
                        <Row>
                            <Col xs="12" className='gray-text'>
                                Average Rating
                            </Col>
                            <Col xs="12"><h4 style={textColor}>{defaultRating[0].rating}</h4></Col>
                        </Row>
                    </Col>
                    
                </Row>
            </Col>
        </Row>
    )
    }else{
        return (
          
            <Col md="12" xs="12">
                <Row className={`crating ${customClass != undefined ? customClass : ""}`}>
                    <Col xs="4"></Col>
                    <Col xs="2"><h1 style={{color: defaultRating[0].color, fontSize: "6rem", whiteSpace: "nowrap" }}>{defaultRating[0].score != "N/A" ? defaultRating[0].score : ""}</h1></Col>
                    <Col xs="4">
                        <Row>
                            <Col xs="12" className='gray-text' style={{fontSize:"1.75rem"}}>
                                Average Rating
                            </Col>
                            <Col xs="12"><h4 style={{color: defaultRating[0].color, marginBottom: "16px",fontSize:"1.75rem" }}>{defaultRating[0].rating}</h4></Col>
                        </Row>
                    </Col>
                    <Col xs="4"></Col>
                </Row>
            </Col>
       
        )
    }

}
export default CustomerRatingScore;