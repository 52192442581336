import { Counter } from "../pages/Counter";
import { FetchData } from "../pages/FetchData";
import Home from "../pages/Home/Home";
import Portfolio from "../pages/Portfolio/Portfolio";
import Login from "../authentication/Login";
import AddEditSupplier from "../pages/AddEditSupplier/AddEditSupplier";
import BusinesImpact from "../pages/Administration/BusinessImpact/BusinessImpact";
import BusinesImpactAddEdit from "../pages/Administration/BusinessImpact/BusinessImpactAddEdit";
import SupplierPage from "../pages/Supplier/Supplier";
import Assets from "../pages/Assets/Assets";
import Questionaries from "../pages/Administration/Questionaties/Questionary";
import QuestionariesAddEdit from "../pages/Administration/Questionaties/QuestionariesAddEdit";
import Administration from "../pages/Administration/Administration";
import Reports from "../pages/Reports/Reports";
import Profiles from "../pages/Administration/Profiles/Profiles";
import Users from "../pages/Administration/Users/Users";
import Ratings from "../pages/Administration/Rating/Rating";
import Threats from "../pages/Threats/Threats";

const AppRoutes = [
  {
    index: true,
    name: "Dashboard",
    path: '/',  
    element:<Home />,
    render:(props)=><Home {...props} />
  },
  {
    name: "Portfolio",
    path: '/portfolio',
    element:<Portfolio />,    
  },
  {
    name: "Suppliers",
    path: '/portfolio/suppliers/:id',
    element:<SupplierPage />   
  },
  ,
  {
    name: "Supplier Assets",
    path: '/portfolio/suppliers/:id/assets',
    element:<Assets />   
  },
  {
    name: "Add Supplier",
    path: '/portfolio/add',
    element:<AddEditSupplier />    
  },
  {
    name: "Edit Supplier",
    path: '/portfolio/edit/:SupplierId',
    element:<AddEditSupplier />    
  },
  {
    name: "Threats",
    path: '/threats',
    element: <Threats/>
  },
  {
    name: "Reports",
    path: '/reports',
    element: <Reports/>
  },
  {
    name: "Administration",
    path: '/admin',
    element: <Administration/>
  },
  {
    name: "Business Impact",
    path: '/admin/businessimpact',
    element: <BusinesImpact />
  },
  {
    name: "Business Impact Add",
    path: '/admin/businessimpact/add',
    element: <BusinesImpactAddEdit />
  },
  {
    name: "Business Impact Edit",
    path: '/admin/businessimpact/edit:id',
    element: <BusinesImpactAddEdit />
  },
  {
    name: "Questionaries",
    path: '/admin/questionaries',
    element: <Questionaries />
  },
  {
    name: "Questionary Add",
    path: '/admin/questionaries/add',
    element: <QuestionariesAddEdit />
  },
  {
    name: "Questionary Edit",
    path: '/admin/questionaries/edit/:id',
    element: <QuestionariesAddEdit />
  },
  {
    name: "Users",
    path: '/admin/users',
    element: <Users/>
  },
  {
    name: "Edit Users",
    path: '/admin/users/edit:id',
    element: <Administration/>
  },
  {
    name: "Add Users",
    path: '/admin/users/add',
    element: <Administration/>
  },
  {
    name: "Profiles",
    path: '/admin/profiles',
    element: <Profiles/>
  },
  {
    name: "Rating",
    path: '/admin/rating',
    element: <Ratings/>
  }
]

export default AppRoutes;
