import React, { useState,useEffect } from 'react';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import '@inovua/reactdatagrid-enterprise/index.css';
import "./Table.css";
const DataTable = (props) => {
    const { columns, data, id,rowHeight,pagination,showHeader } = props;
    const [gridRef, setGridRef] = useState(null);
    const rowLimit=props.defaultLimit>data.length?data.length:props.defaultLimit;
    const gridStyle = { minHeight:!rowHeight && !pagination?'500px':((pagination?rowLimit:data.length)*rowHeight+(props.showHeader==false?0:rowHeight))+35+'px' }     
    useEffect(() => {
       
        if (gridRef && gridRef.current?.setColumnSizesToFit!=undefined) {
            gridRef.current?.setColumnSizesToFit();
            //gridRef.current?.setColumnSizesAuto();
        }      
    }, [gridRef])
    return (
        <ReactDataGrid licenseKey={"key=1234,ExpiryDate=3022-03-25,Z=12783300801648401695"} handle={setGridRef} virtualized={true}   enableColumnAutosize={true} showCellBorders={false}  reorderColumns={false} columns={columns} dataSource={data} style={gridStyle} {...props} />
    )
}
export default DataTable;