import QuestionaryCalculator from "./questionaryCalculator";
import RatingFormulaCalculator from "./ratingFormulaCalculator";
import moment from 'moment';
const RatingCalculator = (value) => {
  const fromMin = 0;
  const fromMax = 100;
  const toMin = 0;
  const toMax = 5;
  const normalizedValue = (value - fromMin) / (fromMax - fromMin);

  // Then, reverse the normalized value to achieve the desired mapping
  const reversedNormalizedValue = 1 - normalizedValue;

  // Map the reversed normalized value to the new range
  const result = (toMax - toMin) * reversedNormalizedValue + toMin;

  return result;
}

export const PostureRatingSupplier = (supplier, formula, month) => {
  let SupplierBusinessImpact = -1;


  let fRating = []; //Rating array for all supplier
  let Rating = [];  //Rating array for each supplier
  let avgScore = "N/A";
  let QuestionaryRating = -1;
  let suppliersReviewed = 0;
  let positionRating = 0;
  let assetBusinessImpact = 0;
  let a = [];
  //console.log(supplier.Assets);
  let errors = 0;
  // console.log("Month:"+month);
  supplier.Assets.forEach(x => {
    if (x.IsOfInterest) {

      assetBusinessImpact = assetBusinessImpact + x.BusinessImpact;
      if (x.AssetScans.length == 0) {
        errors++;
      }
      if (x.AssetScans.length > 0) {
        if (month == undefined)
          a.push(x.AssetScans.reduce((acc, val) => acc + val.Risk_Level >= 2 ? val.Risk_Level : 0, 0));
        else {
          const mAssets = x.AssetScans.filter(x => x.DateCreated >= moment().subtract(month, 'months').startOf("month").format('YYYY-MM-DD') && x.DateCreated <= moment().subtract(month - 1, 'months').endOf("month").format('YYYY-MM-DD'));
          //console.log("AssetCount:"+mAssets.length);
          a.push(mAssets.length > 0 ? mAssets.reduce((acc, val) => acc + val.Risk_Level >= 2 ? val.Risk_Level : 0, 0) : -1);
        }
      } else { a.push(0); }
    }
  });
  a = a.filter(l => l !== undefined);
  const provisionalPosture = a.reduce((acc, val) => acc + val, 0);
  //console.log("Prov:" + provisionalPosture)
  if (provisionalPosture >= 0) {
    //Posture Calculation
    const posture = a.length > 0 && provisionalPosture > 0 ? (100-provisionalPosture / a.length*100) : 100;
    const PostureRating = posture;

    //Questionary Calculation          
    const qr = (supplier.Questionaries.length > 0 && supplier.Questionaries.filter(x => x.Status == "Approved").length > 0) ? QuestionaryCalculator(supplier.Questionaries[0]) : 0;
    if (supplier.Questionaries.length > 0 && supplier.Questionaries.filter(x => x.Status == "Approved").length > 0) {
      QuestionaryRating = qr;
    }

    const assetbimpact = assetBusinessImpact / supplier.Assets.filter(x => x.IsOfInterest).length;
    switch (supplier.businessImpact) {
      case 4:
        SupplierBusinessImpact = 100;
        break;
      case 3:
        SupplierBusinessImpact = 80;
        break;
      case 2:
        SupplierBusinessImpact = 50;
        break;
      case 1:
        SupplierBusinessImpact = 30;
        break;
      case 0:
        SupplierBusinessImpact = 1;
        break;
    }
    //Rating Calculation
    const values = {
      PostureRating: PostureRating,
      QuestionaryRating: QuestionaryRating,
      BusinessImpact: SupplierBusinessImpact,
      AssetBusinessImpact: assetbimpact
    }

    //console.log(values);
    const avgRating = RatingFormulaCalculator(formula.Formula, values);
    //console.log(avgRating);
    avgScore = avgRating.score;
    if (supplier.Questionaries.length > 0 && supplier.Questionaries.filter(x => x.Status == "Approved").length > 0) {
      suppliersReviewed += supplier.Questionaries.length > 0 ? 1 : 0;
      Rating.push(avgRating.rating);
    }
    fRating.push({
      PostureRating: PostureRating,
      Rating: errors > 0 ? 0 : avgRating,
      QuestionaryRating: QuestionaryRating
    })
  } else {
    fRating.push({
      PostureRating: -1,
      Rating: 0,
      QuestionaryRating: 0
    })
  }
  // console.log(moment().subtract(month, 'months').format('YYYY-MM-DD'));
  // console.log(moment().subtract(month-1, 'months').format('YYYY-MM-DD'));
  return fRating;
}
export const PostureRatingSuppliers = (supplierData, formula) => {
  let fRating = [];
  supplierData.forEach(supplier => {
    fRating.push(PostureRatingSupplier(supplier, formula));
    // Rating = Math.round(1 + 3 * Math.exp(-RatingScale * 10));
  });
  fRating = fRating.flat();
  const avg = fRating.length > 0 ? fRating.filter(x => x.QuestionaryRating != -1).reduce((acc, val) => acc + val.Rating.rating, 0) : 0;
  const avgQ = fRating.length > 0 ? fRating.filter(x => x.QuestionaryRating != -1).reduce((acc, val) => acc + val.QuestionaryRating, 0) : -1;
  const avgRating = avg > 0 ? fRating.filter(x => x.QuestionaryRating != -1).reduce((acc, val) => acc + val.Rating.rating, 0) / fRating.filter(x => x.QuestionaryRating != -1).length : 0;
  const pRating = avg > 0 ? fRating.filter(x => x.QuestionaryRating != -1).reduce((acc, val) => acc + val.Rating.score, 0) / fRating.filter(x => x.QuestionaryRating != -1).length : 0;
  const pQuestionaryRating = avgQ > 0 ? fRating.reduce((acc, val) => acc + val.QuestionaryRating, 0) / fRating.filter(x => x.QuestionaryRating != -1).length : 0;

  return {
    Score: pRating,
    Rating: avgRating,
    avgRating: avgRating,
    QuestionaryRating: pQuestionaryRating
  };
}
export default RatingCalculator;