import React, { createContext, useContext, useEffect, useMemo, useState, useCallback } from "react";
import { useTable, useSortBy, usePagination, useGlobalFilter } from 'react-table';

const DynamicTable = (props) => {
    const { columns, data, id, rowHeight, pagination, showHeader, onSelectionChange } = props;
    const [gridRef, setGridRef] = useState(null);
    // const rowLimit = props.defaultLimit > data.length ? data.length : props.defaultLimit;
    //const gridStyle = { minHeight: !rowHeight && !pagination ? '500px' : ((pagination ? rowLimit : data.length) * rowHeight + (props.showHeader == false ? 0 : rowHeight)) + 35 + 'px' }
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        setGlobalFilter,
        setPageSize,
        state,
        // Pagination
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        getState,
        setPageIndex
    } = useTable(
        {
            columns,
            data,
            initialState: { pageSize: 10 }
        },
        useGlobalFilter, // For searching
        useSortBy,       // For sorting
        usePagination    // For pagination
        );
    const handleClickRow = useCallback((row) => {
        if (onSelectionChange != undefined) {
            onSelectionChange(row)
        }
    },[])
    const { pageIndex, globalFilter, pageSize } = state;
    return <>
        <table {...getTableProps()} className="table table-hover">
            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                {column.render('Header')}
                                {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {page.map(row => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()} onClick={()=>handleClickRow(row)}>
                            {row.cells.map(cell => (
                                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            ))}
                        </tr>
                    );
                })}
            </tbody>
        </table>
        {pagination ? <>
            {/* Pagination Controls */}
            < div className="d-flex justify-content-left align-items-center">
                {/* Page Navigation */}
                <div className="pagination">
                    <button className="btn btn-sm"
                        onClick={() => gotoPage(0)}
                        disabled={!canPreviousPage}
                    >
                        <svg height="24" viewBox="0 0 24 24" width="24"><path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path></svg>
                    </button>
                    <button
                        className="btn btn-sm"
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                    >
                        <svg height="24" viewBox="0 0 24 24" width="24"><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path></svg>
                    </button>
                    {/* Page Size Selection */}
                    <span className="flex items-center gap-1">
                        Page
                        <input
                            type="number"
                            min="1"
                            max={pageCount}
                            defaultValue={pageIndex + 1}
                            onChange={e => {
                                const page = e.target.value ? Number(e.target.value) - 1 : 0
                                gotoPage(page)
                            }}
                            disabled={!canNextPage}
                            className="border p-1 rounded w-16"
                        /> of {pageCount}
                    </span>
                    {/* Page Numbers */}
                    {/*{pageOptions.map((pageNumber) => (*/}
                    {/*    <button*/}
                    {/*        key={pageNumber}*/}
                    {/*        className={`btn btn-sm ${pageIndex === pageNumber ? '' : ''}`}*/}
                    {/*        onClick={() => gotoPage(pageNumber)}*/}
                    {/*    >*/}
                    {/*        {pageNumber + 1}*/}
                    {/*    </button>*/}
                    {/*))}*/}

                    <button
                        className="btn btn-sm"
                        onClick={() => nextPage()}
                        disabled={!canNextPage}
                    >
                        <svg height="24" viewBox="0 0 24 24" width="24"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path></svg>
                    </button>
                    <button className="btn btn-sm"
                        onClick={() => gotoPage(pageCount-1)}
                        disabled={!canNextPage}
                    >
                        <svg height="24" viewBox="0 0 24 24" width="24"><path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path></svg>
                    </button>
                </div>
               
                <div>
                    <select
                        className="form-select form-select-sm"
                        value={pageSize}
                        onChange={(e) => setPageSize(Number(e.target.value))}
                    >
                        {[5,10, 20, 30, 40, 50].map(size => (
                            <option key={size} value={size}>
                                Show {size}
                            </option>
                        ))}
                    </select>
                </div>


            </div >
        </> : null}
    </>;
}
export default DynamicTable;
//// import { Col, Row } from "reactstrap";
//import DataGrid, { SortColumn, SelectColumn } from 'react-data-grid';
//import 'react-data-grid/lib/styles.css';
//import "./Table.css";
//import Pagination from '../Pagination/Pagination';
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faChevronUp, faChevronDown, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
//import { UncontrolledTooltip } from "reactstrap";
//import { FilterContext } from "./filterRender";
//const DynamicTable = (props) => {
//    const { onRowsChange, showTitle, title, rows, style, columns, getRowId, rowHeight, headerHeight, defaultsortColumns, pagination = false, PageSize = 6, rowSelect = false, buttons, tips,filterFields ,filteredRows } = props;
//    const [sortColumns, setSortColumns] = useState(defaultsortColumns != undefined ? defaultsortColumns : []);
//    const [selectedRow, setSelectedRows] = useState(() => new Set());
//    const [currentPage, setCurrentPage] = useState(1);
//    const [filters, setFilters] = useState(filterFields!=undefined?filterFields:{});
//    useEffect(() => {
//        //console.log(rows.length);
//    }, [rows])
//    if (rowSelect) {
//        columns.unshift(SelectColumn);
//    }
//    const getComparator = ((sortColumn) => {
//        return (a, b) => {
//            switch (typeof (a[sortColumn])) {
//                case "string":
//                    return a[sortColumn].localeCompare(b[sortColumn]);
//                    break;
//                case "date":
//                    return Number(a[sortColumn]) == Number(b[sortColumn]) ? 0 : Number(a[sortColumn]) > Number(b[sortColumn]) ? 1 : -1;
//                    break;
//                case "number":
//                    return a[sortColumn] - b[sortColumn];
//                    break;
//            }

//        };
//    })
//    const sortedRows = useMemo(() => {
//        if (sortColumns.length === 0) return rows;

//        return [...rows].sort((a, b) => {
//            for (const sort of sortColumns) {
//                const comparator = getComparator(sort.columnKey);
//                const compResult = comparator(a, b);
//                if (compResult !== 0) {
//                    return sort.direction === 'ASC' ? compResult : -compResult;
//                }
//            }
//            return 0;
//        });
//    }, [rows, sortColumns]);

//    const currentTableData = useMemo(() => {
//        const firstPageIndex = (currentPage - 1) * PageSize;
//        const lastPageIndex = firstPageIndex + PageSize;
//        return sortedRows.slice(firstPageIndex, lastPageIndex);
//    }, [currentPage, sortColumns, rowSelect, rows]);

//    const rowKeyGetter = (row) => {
//        return row.id != undefined ? row.Id : row.Id;
//    }
//    const sortPriorityClassname = "dcs-sort";
//    const renderSortStatus = ({ sortDirection, priority }) => {
//        return (
//            <>
//                {sortDirection !== undefined ? (sortDirection === 'ASC' ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />) : <div style={{
//                    display: "flex",
//                    flexDirection: "column",
//                    flexWrap: "nowrap",
//                    alignItems: 'center',
//                    fontSize: "10px",
//                    marginTop: "10px"
//                }}><FontAwesomeIcon icon={faChevronUp} /><FontAwesomeIcon icon={faChevronDown} /></div>}
//                {/* <span className={sortPriorityClassname}>{priority}</span> */}
//            </>
//        );
//    }

//    //FILTER REGION




//    //END FILTER REGION
//    return (
//        <>
//            <div>
//                {
//                    showTitle ? <h5 className="dcs-Title">{title} {tips ? <> <span class="tips">
//                        <FontAwesomeIcon icon={faExclamationCircle} id={"tips-questionarysent"} /></span>
//                        <UncontrolledTooltip placement="bottom"
//                            target={"tips-questionarysent"}>
//                            {tips}
//                        </UncontrolledTooltip></> : null}
//                    </h5> : null

//                }
//            </div>
//            <div>
//                {
//                    currentTableData.length > 0 ?
//                         <FilterContext.Provider value={filters}>
//                            <DataGrid dark className={`${filters.enabled ? "filterContainerClassname":undefined} fill-grid dcs-table ${props.className && props.className} `}
//                                rows={currentTableData}
//                                columns={columns}
//                                rowKeyGetter={rowKeyGetter}
//                                onSelectedRowsChange={setSelectedRows}
//                                rowHeight={rowHeight}
//                                style={style}
//                                //rowHeight={(row) => (row.type === 'DETAIL' ? 300 : rowHeight!=undefined?rowHeight:45)}
//                                onRowsChange={onRowsChange}
//                                headerRowHeight={filters.enabled ? 90 : headerHeight}
//                                selectedRows={selectedRow}
//                                defaultColumnOptions={{
//                                    sortable: true,
//                                    resizable: true
//                                }}
//                                renderers={{ renderSortStatus }}
//                                //headerRowHeight={headerRowHeight}
//                                onSortColumnsChange={!filters.enabled?setSortColumns:undefined}
//                                sortColumns={sortColumns}
//                            />
//                        </FilterContext.Provider>
//                        : <div className="col-md-12 text-center">No data to display</div>}
//            </div>
//            {pagination ?
//                <Pagination
//                    key="dtpagination"
//                    className="pagination-bar"
//                    currentPage={currentPage}
//                    totalCount={rows.length}
//                    pageSize={PageSize}
//                    onPageChange={page => setCurrentPage(page)}
//                /> : null}
//        </>
//    )
//}
//export default DynamicTable;