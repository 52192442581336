import axios from 'axios';
const Anaf = (initCui, callBack) => {
    if (initCui !== undefined) {
        const CUI = Number(initCui);
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
        const currentDate = new Date().getFullYear() + "-" + (new Date().getMonth() < 10 ? "0" + new Date().getMonth() : new Date().getMonth()) + "-" + new Date().getDate();
        const ANAF_API = "/suppliers/anaf";
        const params = {
            cui: CUI
        }
        axios.get(ANAF_API, { params }).then((result) => {
            try {
                result.data = JSON.parse(result.data);
            }
            catch {
                callBack({});
            };
            if (result.data != undefined) {

                if (result.data.cod === 200) {
                    callBack(result.data.found[0]);
                } else {
                    callBack(undefined);
                }
            } else {
                callBack(undefined);
            }
        }).catch(ex => {
            callBack(undefined);
        })
    } else {
        callBack(undefined);
    }

}

export default Anaf;