import React, { useState, useMemo, useEffect } from 'react'
import { ReactFormBuilder, Registry, ElementStore } from 'react-form-builder2';
import 'react-form-builder2/dist/app.css';
import { Col, Row } from 'reactstrap';
import FormElementsEdit from '../../../components/ui/Designer/form-elements-edit';
import FormBuilderPreview from '../../../components/ui/Designer/form-builder-preview';
import DcsToggle from '../../../components/ui/Designer/Toggle/DcsToggle';
import { GetQuestionary } from '../../../data/questionary';
import { useParams } from 'react-router-dom';
import LoaderContainer from '../../../components/ui/LoaderContainer/LoaderContainer';


const QuestionariesAddEdit = (props) => {
    const { id } = useParams();
    const Title = id != undefined ? "Questionary Edit" : "Questionary Add";
    const [formData, setFormData] = useState([]);
    const [questionaryWeight,setQuestionaryWeight]=useState(null);
    const [questionaryName, setQuestionaryName] = useState("");
    const c = Registry.get("DCSToggle");
    if (c == undefined) {
        Registry.register('DCSToggle', DcsToggle);
    }
    useEffect(() => {

        GetQuestionary(id).then(rez => {
            console.log(rez.data);
            setQuestionaryName(rez.data.Name);
            if (rez.data != undefined && id != undefined) {
                setQuestionaryWeight(rez.data.Weight);
                setFormData(JSON.parse(rez.data.Questionary));
            }
        })
    }, [])
    ElementStore.subscribe(state => {
        if (state.data.length > 0) {
            setFormData(state.data);
        }
    })
    const onPost = (data) => {
        // console.log(data.tasks);
        return;
    }

    const items = [{
        key: 'DCSToggle',
        element: 'CustomElement',
        component: DcsToggle,
        type: 'custom',
        forwardRef: true,
        bare: true,
        field_name: 'dcsToggle_input_',
        name: 'Toggle',
        icon: 'fa fa-cog',
        props: { test: 'test_input' },
        label: 'Label Input',
    }, {
        key: 'Header',
    }, {
        key: 'TextInput',
    }, {
        key: 'RadioButtons',
    }, {
        key: 'Checkboxes',
    }, {
        key: 'Label',
    }
    ]
    return (
        <>
            <Row>
                <Col>
                    <h3 className="dcs-Title bold">{Title}</h3>
                </Col>
            </Row>
            <Row>
                <Col className="black">
                    {(formData.length > 0 && id != undefined) || id == undefined ?
                        <>
                            <FormBuilderPreview weight={questionaryWeight} data={formData} getQuestionaryName={questionaryName} />
                            <ReactFormBuilder toolbarItems={items} data={formData}
                                onPost={data => onPost(data)} saveAlways={false} renderEditForm={props => <FormElementsEdit {...props} />} /></> : <LoaderContainer />}
                </Col>
            </Row>
        </>
    )
}
export default QuestionariesAddEdit;