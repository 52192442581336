import React from 'react';
import { Col, Form, FormGroup, Input, Label,Row } from 'reactstrap';

const SupplierFilters = () => {
    return (
        <Row>
            <Col>
                <Form>
                    <FormGroup>
                        <Label for="exampleSelect" >Select</Label>
                        
                            <Input type="select" name="select" id="exampleSelect">
                                <option>--Please select---</option>
                            </Input>
                        
                    </FormGroup>
                </Form>
            </Col>
        </Row>
    )
}
export default SupplierFilters;