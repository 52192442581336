import React from 'react';
import "./Card.css";

const Card=(props)=>{
    const styles={
        padding:props.padding?props.padding:20+"px" ,
            
    }
    return(
        <div className='card bg-primary' style={styles}>
            {props.children}
        </div>
    )
}

export default Card;