const QuestionaryCalculator = (questionary) => {
    if(questionary.Questionary == undefined){
        return -1;
    }
    let q = JSON.parse(questionary.Questionary.Questionary);
    //console.log(q);
    let r = JSON.parse(questionary.Response);
    //console.log(r);
    const farray = r.map(x => {
        let qweight = q.filter(z => z.id == x.id)[0].weigth;
        let rweight = 0;
        if (q.filter(z => z.id == x.id)[0].key == "DCSToggle") {
            x.value == "true" ? rweight = 1 : rweight = 0;

        } else {
            const selq = q.filter(z => z.id == x.id)[0];
            rweight = selq.options.filter(o => o.key == x.value[0])[0].value;

        }
       // console.log(qweight * rweight);
        return qweight * rweight;
    });
  
   // console.log("QRating:"+farray.reduce((acc,val)=>acc+val,0));
   return farray.reduce((acc,val)=>acc+val,0);
}
export default QuestionaryCalculator;