import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faFilter, faPen } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'reactstrap';


/** 
* Title: IconModal
* Functionality: Creates a button with an icon
* @param icon: Variable that contains the icon name;
* @param onClick: Callback function when the button is pressed
**/
const IconButton = (props) => {
    const { icon, onClick,disabled } = props;

    //Map props.icon to a FontAwesome icon
    const GetIcon = (icon) => {
        switch (icon) {
            case "faUserPlus":
                return faUserPlus;
                break;
            case "faFilter":
                return faFilter;
                break;
            case "faPen":
                return faPen;
                break;
        }
    }
    return (
        <Button className="DefaultButton" onClick={!disabled?onClick:null}>
            <FontAwesomeIcon icon={GetIcon(icon)} />
        </Button>
    )
}
export default IconButton;