import React, { useRef, useState, useEffect, createRef } from 'react';

import { Col, Form, Label, Button, Input, FormGroup, ButtonGroup } from 'reactstrap';
import { useAuth } from '../../../contexts/authContext';
import Modal from '../../../components/ui/Modal/Modal';
import { getRoles, saveUser, updateUser } from '../../../data/administration';
import Select from 'react-select';
import moment from 'moment';
const UserAddModal = (props) => {
    const { id, setShowModal, user, callback } = props;
    const auth = useAuth();
    const ClientId = auth.user.ClientId;
    const [isLoding, setIsLoading] = useState(null);
    const [roles, setRoles] = useState(null);
    const [selectedRole, setSelectedROle] = useState(null);
    const [isDomainuser, setIsDomainUser] = useState(false);
    const [forms, setForms] = useState([]);
    const addModalRef = useRef();
    const adduserFormRef = useRef();
    const nameRef = useRef();
    const emailRef = useRef();
    const passwordRef = useRef();
    const isdomainRef = useRef();
    const roleRef = useRef();
    //Dropdown Styles
    const dropdownStile = {
        control: (styles) => ({ ...styles, backgroundColor: '#FFF' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isDisabled ? "#1861ac" : isSelected ? "#1861ac" : isFocused ? "#1861ac" : undefined,
                color: isDisabled ? undefined : isSelected ? "#FFF" : isFocused ? "#FFFFFF" : "#1861ac",
            }
        },
        placeholder: (styles) => ({ ...styles, color: "#999" }),
        singleValue: (styles, { data }) => ({ ...styles, backgroundColor: "#FFF", color: "#1D56A3" })
    }


    useEffect(() => {
        getRoles(null, ClientId).then(rez => {
            if (rez.data.length > 0) {
                const r = rez.data.map((x) => { return { value: x.Id, label: x.Name } })
                setRoles(r);
            }
        });

    }, []);

    useEffect(() => {
        if (user != undefined && roles != null) {
            setSelectedROle(roles.filter(x => x.value === user.RolePermission.RoleId));
            roleRef.current.setValue(roles.filter(x => x.value === user.RolePermission.RoleId));
        }
    }, [roles])
    //Dropdown Styles
    const isDomainUser = () => {
        setIsDomainUser(!isDomainuser);
    }

    const SaveUser = (e) => {
        e.preventDefault();
        const usr = {
            ClientId: ClientId,
            Name: nameRef.current.value,
            Email: emailRef.current.value,
            Password: user != undefined ? user.Password : passwordRef.current.value,
            RolePermission: user != undefined ? {
                Id: user.RolePermission.Id,
                CreatedAt: user.RolePermission.CreatedAt,
                CreatedBy: auth.user.Id,
                RoleId: roleRef.current.getValue()[0].value,
                UserId: user.Id
            } : {
                Id: null,
                CreatedAt: moment().format("YYYY-MM-DD HH:MM:SS"),
                CreatedBy: auth.user.Id,
                RoleId: roleRef.current.getValue()[0].value,
                UserId: null
            }
        }
        console.log(usr);
        if (user != undefined) {
            usr.Id = user.Id;
            updateUser(usr).then(rez=>{
                callback();
                setShowModal(false);
            })
        }
        else {
            saveUser(usr).then(rez => {
                callback();
                setShowModal(false);
            })
        }
    }

    return (
        <Modal title="Add User" ref={addModalRef} callBack={setShowModal}>
            <Col>
                <Form className='row' ref={adduserFormRef} onSubmit={SaveUser}>
                    <FormGroup>
                        <Label>
                            Full Name
                        </Label>
                        <Input innerRef={nameRef} placeholder="John Doe" defaultValue={user != undefined ? user.Name : ""} />
                    </FormGroup>
                    <FormGroup>
                        <Label>
                            Email
                        </Label>
                        <Input innerRef={emailRef} placeholder="example@example.com" disabled={user != undefined ? true : false} defaultValue={user != undefined ? user.Email : ""} />
                    </FormGroup>
                    <FormGroup>
                        <Label>
                            Role
                        </Label>
                        <Select
                            //defaultValue={selectedRole}
                            ref={roleRef}
                            className='col-md-12'
                            styles={dropdownStile}
                            options={roles}
                            placeholder="Select Role" />
                    </FormGroup>
                    {
                        user == undefined ?
                            <FormGroup check>
                                <Input innerRef={isdomainRef} onChange={isDomainUser} type="checkbox" />
                                {' '}
                                <Label check>
                                    Is domain user
                                </Label>
                            </FormGroup> : null
                    }

                    {
                        !isDomainuser && user == undefined ?
                            <FormGroup>
                                <Label>
                                    Password
                                </Label>
                                <Input innerRef={passwordRef} type="password" placeholder="" />
                            </FormGroup> : null
                    }
                    <input type="hidden" name="Id" defaultValue={user != undefined ? user.Id : ""} />
                    <ButtonGroup>
                        <Button className='primary'>Save</Button>
                        <Button className='btn-danger'>Delete</Button>
                    </ButtonGroup>

                </Form>
            </Col>
        </Modal>
    )
}
export default UserAddModal;