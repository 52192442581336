import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { DeleteQuestionaryAPI, GetQuestionary, SaveQuestionary } from '../../../data/questionary';
import LoaderContainer from '../../../components/ui/LoaderContainer/LoaderContainer';
import Card from '../../../components/ui/Card/Card';
import DataTable from '../../../components/ui/Table/DataTable';
import { useAuth } from '../../../contexts/authContext';


const Questionaries = (props) => {
    const Title = "Questionaries";
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [questionaries, setQuestionaries] = useState([]);
    const [selectedQuestionary, setSelectedQuestionary] = useState(null);
    const initialSupplierSort = [

    ]
    const auth = useAuth();
    // const EditQuestionary = (_id) => {
    //     navigate("/admin/questionaries/edit/" + _id);
    // }


    useEffect(() => {
        setIsLoading(true);
        GetQuestionary(null, auth.user.ClientId).then(rez => {
            setQuestionaries(rez.data);
            setIsLoading(false);
        })
    }, [])
    const DeleteQuestionary = (questionary) => {
        setIsLoading(true);
        DeleteQuestionaryAPI(questionary.Id).then(r=>{
            GetQuestionary(null, auth.user.ClientId).then(rez => {
                setQuestionaries(rez.data);
                setIsLoading(false);
            })
        })
    }
    
    const DuplicateQuestionary = (questionary) => {
        setIsLoading(true);
        questionary.Deletable=1;
        questionary.Id=null;
        questionary.ClientId=auth.user.ClientId;
        questionary.Name=questionary.Name+" Copy";
        SaveQuestionary(questionary).then(requestIdleCallback=>{
            GetQuestionary(null, auth.user.ClientId).then(rez => {
                setQuestionaries(rez.data);
                setIsLoading(false);
            })
        })
    }
    const columns = [
        {
            header: 'Name',
            name: 'Name',
            defaultFlex: 1,
        },
        {
            header: '',
            name: 'editbnt',
            resizable: false,
            defaultFlex: 1,
            showColumnMenuTool: false,
            render: ({ data }) => {

                return <div className="text-right">
                    <Button className="DefaultButton" onClick={() =>DuplicateQuestionary(data)}>
                        Duplicate
                    </Button>
                    <Button className="DefaultButton" disabled={!data.Deletable} onClick={() => navigate(`/admin/questionaries/edit/${data.Id}`)}>
                        Edit
                    </Button>
                    <Button color="danger" disabled={!data.Deletable} onClick={() => DeleteQuestionary(data)}>
                        Delete
                    </Button>
                </div>
            }
        }
    ];
    return (<>
        <Row className="white">
            <Col xs md="12" className="headerText">
                <h3 className="dcs-Title bold">{Title}</h3>
                <Button className='btn-primary' size="sm" onClick={() => { navigate("/admin/questionaries/add") }}>Add Questionary</Button>
            </Col>
        </Row>
        <Row className="white">
            <Col>
                <Card>
                    {!isLoading && questionaries.length > 0 ?
                        <DataTable showTitle={false} rowHeight={50} pagination={false} defaultsortColumns={initialSupplierSort} showZebraRows={false} rowSelect={false} title="Questionaries" data={questionaries} showCellBorders={false} columns={columns} /> : <LoaderContainer />}
                </Card>
            </Col>

        </Row >
    </>)
}
export default Questionaries;