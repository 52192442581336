import React from 'react';
import { UncontrolledDropdown, Dropdown, Button, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import "./UserControl.css";
import { Link } from 'react-router-dom';
import { useAuth } from '../../../contexts/authContext';


const UserControl = () => {
    const Logout = () => {

    }
    const authcontext = useAuth();
    //console.log(authcontext);
    return (
        <div>
            <UncontrolledDropdown group>
            {/* <img src="/images/dcslogo2.png" className='userpicture' />  */}
                <DropdownToggle className='userinfoDropdown' caret><span>{authcontext.user.Name}</span></DropdownToggle>
                <DropdownMenu>
                    <DropdownItem>
                        Profile
                    </DropdownItem>
                    <Link to="/logout"  ><DropdownItem>Logout</DropdownItem></Link>
                </DropdownMenu>
            </UncontrolledDropdown>
        </div>
    )

}
export default UserControl;