import axios from 'axios';

export const HostSupplier=(ni)=>{
    const _url="/suppliers/gethostsupplier";
    return axios.post(_url,{_NationalIdentifier:ni});
}
export const SupplierByCui = (ni) => {
    
    const _url="/suppliers/getsupplier";
    return axios.post(_url,{_NationalIdentifier:ni});
}
export const SaveSupplier=(supplier)=>{
    supplier.DateCreated=new Date();
    const _url="/suppliers";
    return axios.post(_url,  supplier );
}
export const Suppliers = (_id, ClientId, months) => {   
        const _url = "/suppliers";
        const params = {
            Id: _id,
            ClientId: ClientId,
            months: months
        }
        return axios.get(_url, { params });   
}
export const DeleteSupplier = (ClientId, _id) => {
    const _url = "/suppliers";
    const params = {
        Id: _id,
        ClientId: ClientId       
    }
    return axios.delete(_url, { params });
}
export const UpdateSupplier=(supplier)=>{
    const _url="/suppliers";
    return axios.put(_url,supplier);
}
export const GetSupplierRatings = (ClientId, months) => {
    const _url = "/suppliers/SuppliersRating";
    const params = {      
        ClientId: ClientId,
        months: months
    }
    return axios.get(_url, { params });    
}
export const RefreshAssets=(_supplierId,_clientid)=>{    
    const _url="/suppliers/refreshAssets";
     const params={      
        _SupplierId:_supplierId,
        ClientId:_clientid
    }
    return axios.get(_url,{params});
}