import axios from 'axios';


export const SaveContactAPI=(contact)=>{
    contact.DateCreated=new Date();
    const _url="/contacts";
    return axios.post(_url,contact);
}
export const GetContactsAPI=(_id,_SupplierId)=>{
   
    const _url="/contacts";
     const params={
        id:_id,
        SupplierId:_SupplierId
    }   
    return axios.get(_url,{params});
}
export const UpdateContactAPI=(contact)=>{
    const _url="/contacts";
    return axios.put(_url,contact);
}
export const DeleteContactAPI=(contactId)=>{
    //console.log(contactId);
    const _url="/contacts";
    return axios.delete(_url,{params:{contactId:contactId}})//,contactId.Id);
}