import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { DeleteQuestionaryAPI, GetQuestionary } from '../../../data/questionary';
import LoaderContainer from '../../../components/ui/LoaderContainer/LoaderContainer';
import Card from '../../../components/ui/Card/Card';
import DataTable from '../../../components/ui/Table/DataTable';
import { getRatings, updateRating } from '../../../data/administration';
import { useAuth } from '../../../contexts/authContext';
import RatingFormulaCalculator from '../../../utils/ratingFormulaCalculator';
import moment from 'moment';

const Ratings = () => {
    const Title = "Rating Formula";
    const [rating, setRating] = useState(null);
    const [calculatedRating, setCalculatedRating] = useState(null);
    const authContext = useAuth();
    const formularef = useRef(null);
    const auth = useAuth();
    useEffect(() => {
        getRatings(authContext.token, auth.user.ClientId).then(rez => {
            if (rez != undefined) {
                setRating(rez.data);
                // console.log(rez.data);
            }
        })
    }, [])
    const mapRange=(value)=> {
        return (value - 1) * (100 - 0) / (4 - 1) + 0;
    }
    const simulate = () => {
        if (formularef.current != null) {
            const PostureRating = 100;
            const QuestionaryRating = 100;
            const BusinessImpact = mapRange(4);
            const AssetBusinessImpact = mapRange(4);
            // console.log(formularef.current.value);
            const values = {
                PostureRating: PostureRating,
                QuestionaryRating: QuestionaryRating,
                BusinessImpact: BusinessImpact,
                //AssetBusinessImpact: AssetBusinessImpact
            }
            console.log(values);
            const f = RatingFormulaCalculator(formularef.current.value, values);
            //const f = formularef.current.value.replace("PostureRating", PostureRating).replace("QuestionaryRating", QuestionaryRating).replace("BusinessImpact", BusinessImpact).replace("AssetBusinessImpact", AssetBusinessImpact);
            try {
                setCalculatedRating(Number.parseInt(eval(f.score).toFixed(0)))
            }
            catch {

                setCalculatedRating("Evaluation error");
            }

        }
    }
    const SaveRating = () => {
        if (formularef.current != null) {
            let rating = {
                Formula: formularef.current.value,
                CreatedBy: auth.user.Id,
                DateCreated: moment().format("YYYY-MM-DD"),
                Current: true,
                ClientId: auth.user.ClientId
            }

            updateRating(auth.otken, rating).then(rez => {
                console.log(rez);
            })
        }
    }
    return (
        rating != null ?
            <>
                <Row className="white">
                    <Col className="headerText" md={2}>
                        <h3 className="dcs-Title bold">{Title}</h3>
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>
                        <h4>Available variables</h4>
                        <ul>
                            <li><b>PostureRating</b> - The result between 0 and 100 from the scanned assets</li>
                            <li><b>QuestionaryRating</b> - The result between 0 and 100 from the questionary answers</li>
                            <li><b>BusinessImpact</b> - Declared business impact of the supplier, Value is between 0 and 4 where 0 is  </li>
                            <li><b>DarkWebPosture</b> -The result between 0 and 100 from the scanned assets on the DarkWeb</li>
                        </ul>
                    </Col>
                    <Col>
                        <h4>Formula</h4>
                        <textarea ref={formularef} rows={5} className="form-control" defaultValue={rating.Formula}>

                        </textarea>
                    </Col>
                </Row>
                <Row>
                    <Col md={11}>
                        <Button className="btn-primary" onClick={simulate}>Simulate</Button>   {
                            calculatedRating != null ? <>Simulation Result:{typeof (calculatedRating) == 'number' ? <b style={{ color: "green" }}>{calculatedRating}</b> : <b style={{ color: "red" }}>{calculatedRating}</b>}</> : null
                        }
                    </Col>
                    <Col md={1}>
                        {auth.user.RolePermission.Role.PermissionActions.filter(x => x.ActionP.Name == "Edit" && x.Permission.Name == "Rating").length > 0 ?
                            <Button className="btn-primary ml-auto" onClick={SaveRating}>Save</Button> : null}
                    </Col>
                </Row>


            </> : <LoaderContainer />);
}
export default Ratings;